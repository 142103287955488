import React from 'react';
import { useFormContext } from 'react-hook-form';

import CustomCodesInfoOverlay from './CustomCodesInfoOverlay';
import FacilityLayout from '../../../../../../components/Layouts/Facility/FacilityLayout';
import TimeWithDuration from '../../../../../../components/Layouts/Time/TimeWithDuration';

import { Divider } from 'primereact/divider';
import { Tooltip } from 'primereact/tooltip';
import { Message } from 'primereact/message';

import { elementIDs } from '../../../../../config/elementIDsConfig';
import { currencyFormat } from '../../../../../utils/currencyFormat';
import { formatPHN } from '../../../../../utils/formatPHN';
import { calculateGroupTotal } from '../../helpers/calculateGroupTotal';
import { showMultipleServiceDate } from '../../helpers/inputsConditions';
import { formatDate, isWeekendOrHoliday } from '../../../../../utils/formatDate';
import { location_codes, rural_codes } from '../../../../../common/contants';
import { anatomicList, submissionCodes } from '../../../../helpers/mapper';
import { inputs } from '../../helpers/inputs';
import { invoiceTypes } from '../../helpers/defaultValues';
import { useSelector } from 'react-redux';
import { parseMilitaryTime } from '../../../../../utils/formatTime';
import { commonInputs } from '../../../../../config/commonInputsConfig';
import { specPHN } from '../../../../../config/defaultValuesConfig';
import { isSupplementary } from '../../../../../config/specialitiesConfig';
import { groupBy, isArray } from 'lodash';
import { t } from '../../../../../../service/localization/i18n';
import cx from 'classnames';
import moment from 'moment';
import { Button } from 'primereact/button';

const PatientInfo = () => {
  const { watch } = useFormContext();

  const phn = (patient) => {
    if (watch(commonInputs.submitWithGenericPHN.name) === '1') return specPHN;
    if (patient?.PHNProvince && patient?.PHN && patient?.PHN !== 'N/A') {
      return `${patient?.PHNProvince} ${formatPHN(patient?.PHN, patient?.PHNProvince)}`;
    }
    return formatPHN(patient?.PHN);
  };

  return (
    <>
      {watch(inputs.patient.name)?.map((i, index) => {
        return (
          <li className="flex align-items-start pl-2 mb-3" key={i?.PatientGuid}>
            <div className="grid mr-2">{`${index + 1}.`}</div>
            <div className="grid">
              <div id={`previewPatientName_${i.PatientGuid.replaceAll('-', '').toUpperCase()}`} className="ml-2 font-bold">
                {`${i?.LastName}, ${i?.FirstName} ${i?.MidName || ''}`}
              </div>
              <div id={`previewPatientPHN_${i.PatientGuid.replaceAll('-', '').toUpperCase()}`} className="ml-2">
                {`${phn(i)}, ${formatDate(i?.BirthDay)}`}
              </div>
              {i.LfpPanel && <div>{`, ${t('LFP')}`}</div>}
            </div>
          </li>
        );
      })}
    </>
  );
};

const TeleplanInvoicePreview = () => {
  const practitioners = useSelector((state) => state.clinic.members);
  const { watch, localState, isMobile } = useFormContext();

  const serviceDate = watch(inputs.serviceDate.name);
  const currentServiceDate = isArray(serviceDate) && serviceDate.length ? serviceDate[0] : serviceDate;
  const speciality = watch(inputs.speciality.name);
  const invoiceType = watch(inputs.payor.name);
  const facility = watch(inputs.facility.name);
  const subFacility = watch(inputs.subFacility.name);
  const ruralCode = rural_codes.find((i) => i.value === watch(inputs.ruralCode.name)?.value);
  const locationCode = location_codes.find((i) => i.value === watch(inputs.locationCode.name));
  const submissionCode = watch(inputs.submission.name) ? submissionCodes.find((i) => i.value === watch(inputs.submission.name)) : submissionCodes[0];
  const showMultipleDates = localState.isMultipleDates;
  const records = localState.records;
  const groupedRecordsByServiceDate = groupBy(records, 'service_date');

  const previewBillingCodeWidth = 165;
  const codeWrapWidth = 86;
  const currencyWidth = 67;

  const serviceTime = (i) => {
    const startTime = i.service_start < 0 || i.service_start === '' || i.service_start === null ? '' : parseMilitaryTime(i.service_start);
    const endtTime = i.service_finish < 0 || i.service_finish === '' || i.service_finish === null ? '' : parseMilitaryTime(i.service_finish);

    const noTime = (
      <div className="flex mb-1">
        <div className="mobileCodesTitle">{`${t('Time')}:`}</div>
        <div id={`startFinishTime_${i.fee_code}`}>{t('N_A')}</div>
      </div>
    );

    const time = (
      <span id={`startFinishTime_${i.fee_code}`}>
        {`${startTime} - ${endtTime}`}
        {/* <TimeWithDuration startTime={startTime} endTime={endtTime} /> */}
      </span>
    );

    if (!startTime && !endtTime) return noTime;

    return time;
  };

  const codes = (i) => {
    return (
      <>
        <div className="flex mb-1">
          <div id={`previewDxCode_${i.fee_code}`}>{`${t('Dx')}: ${i?.icd9?.replaceAll(',', ', ')}`}</div>
        </div>
        {invoiceType === invoiceTypes.wsbc && (
          <div className="flex-column">
            <div className="flex mb-1">
              <div id={`previewAOICode_${i.fee_code}`}>{`${t('Area_of_Injury.2')}: ${watch(inputs.aoi.name)}`}</div>
            </div>

            <div className="flex mb-1">
              <div id={`previewNOICode_${i.fee_code}`}>{`${t('Nature_of_Injury.2')}: ${watch(inputs.noi.name)}`}</div>
            </div>

            <div className="flex">
              <div id={`previewAnatomicCode_${i.fee_code}`}>
                {`${t('Anatomic_Code.2')}: ${anatomicList?.find((i) => i.label === watch(inputs.anatomicCode.name))?.text}`}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const treatedBy = () => {
    const currentPreactitioner = practitioners.find((i) => i.DoctorGuid?.toUpperCase() === watch(inputs.practitioner.name)?.toUpperCase());
    return `${currentPreactitioner?.LastName}, ${currentPreactitioner?.FirstName}` || '';
  };

  const referral = () => {
    const refToByLabel = watch(inputs.refToBy.name) === 'B' ? 'by' : watch(inputs.refToBy.name) === 'T' ? 'to' : '';

    if (watch(inputs.refToBy.name) && watch(inputs.referral.codeDescription)?.length && watch(inputs.referral.name)?.length) {
      return (
        <div id={elementIDs.previewReferral} className="mb-1">
          {`${t('Referred.2')} ${refToByLabel}: #${watch(inputs.referral.name)[0]}`}

          <Tooltip id={elementIDs.previewReferralTooltip} className="locationTooltip" target=".referredInfoIcon" position="top" />
          <i
            id={elementIDs.previewReferralIcon}
            className="referredInfoIcon pi pi-info-circle ml-3 pointer"
            data-pr-tooltip={watch(inputs.referral.codeDescription)[0]}
          />
        </div>
      );
    }

    return null;
  };

  const claimNumberLayout = () => {
    if (invoiceType === invoiceTypes.wsbc) {
      return (
        <div id={elementIDs.previewWSBCClaimNumber} className="mb-1">
          {`${t('Claim')} #${watch(inputs.claimNumber.name) || t('N_A')} / ${formatDate(watch(inputs.injuryDate.name))}`}
        </div>
      );
    }

    if (invoiceType === invoiceTypes.icbc) {
      return (
        <div id={elementIDs.previewWSBCClaimNumber} className="mb-1">
          {`${t('Claim')} #${watch(inputs.claimNumber.name) || t('N_A')}`}
        </div>
      );
    }
  };

  const noteLayout = (record) => {
    if (!record.note) return null;
    return (
      <div id={`previewCodeNote_${record.fee_code}`} className="py-2">
        {record.note}
      </div>
    );
  };

  const duplicateLayout = (record) => {
    if (!record.isDuplicate) return null;
    return <div className="pt-3">{`${t('Submission_Code')}: D`}</div>;
  };

  const serviseDateChipTemplate = <span id={elementIDs.serviceDate}>{formatDate(currentServiceDate)}</span>;

  return (
    <div id={elementIDs.previewPanel} className="flex justify-content-center w-full">
      <div className="mb-4 mt-2 w-full">
        <div className={cx('mt-1', isMobile ? '' : 'p-3')}>
          <div className={cx('flex col-12 p-0')}>
            {/* Doctor */}
            <div className={cx('p-0', isMobile ? 'col-6' : 'col-7')}>
              <div id={elementIDs.previewPractitioner} className="mb-1 font-bold">
                {`${treatedBy().toUpperCase()}`}
              </div>

              <div className="mb-1">
                <div id={elementIDs.previewPayeeNum} className="hidden md:block">{`${t('Practitioner')}/${t('Payee')}: #${watch(
                  inputs.practitionerNumber.name
                )}/#${watch(inputs.payeeNumber.name)}`}</div>
                <div className="block md:hidden">{`#${watch(inputs.practitionerNumber.name)}/#${watch(inputs.payeeNumber.name)}`}</div>
              </div>

              <div className="mb-1">
                <FacilityLayout data={{ facility, subFacility }} />
              </div>

              {ruralCode && !isSupplementary(speciality, invoiceType) && (
                <div id={elementIDs.previewRuralCode} className="mb-1">
                  {ruralCode.label === 'N/A' ? t('No_Rural_Code') : ruralCode.label}
                </div>
              )}

              <div id={elementIDs.previewLocation} className="flex align-items-center mb-1">
                {`${t('Location')}: ${locationCode?.value}`}

                <Tooltip id={elementIDs.previewLocationTooltip} className="locationTooltip" target=".locationInfoIcon" position="top" />
                <i
                  id={elementIDs.previewLocationIcon}
                  className="locationInfoIcon pi pi-info-circle ml-3 pointer"
                  data-pr-tooltip={locationCode?.label}
                />
              </div>

              {referral()}
            </div>

            <div className={cx('pl-3 pr-0 py-0', isMobile ? 'col-6' : 'col-5')}>
              <div id={elementIDs.previewInvoiceType} className="mb-1 font-bold">
                {invoiceType}
              </div>

              {claimNumberLayout()}

              <div className="mb-1">
                {showMultipleDates ? (
                  <div className="multiple_dates_text">{t('Multiple_Dates')}</div>
                ) : (
                  <Message severity="info" content={serviseDateChipTemplate} />
                )}
              </div>

              <div id={elementIDs.previewSubmitionCode} className="mb-1">
                {submissionCode?.label}
              </div>
            </div>
            <div></div>
          </div>

          <Divider />

          {/* {showMultipleDates && Array.isArray(serviceDate) && (
            <div className="flex align-items-center gap-3">
              <Message severity="info" content={t('Service_Dates')} style={{ minWidth: '120px' }} />
              <span className="line-height-3">
                {serviceDate
                  ?.map((i) => moment(i).format('MMM DD'))
                  ?.join()
                  ?.replaceAll(',', ', ')}
              </span>
            </div>
          )} */}

          <div className="my-4">
            <ul className="pl-0 mt-0">
              <PatientInfo />
            </ul>
          </div>

          <div className="mt-3">
            {/* <div id={elementIDs.billing} className="mb-2">
              {t('Billing').toUpperCase()}
            </div> */}

            {Object.keys(groupedRecordsByServiceDate).map((serviceDate) => {
              const isWeekend = isWeekendOrHoliday(serviceDate);
              return (
                <div key={serviceDate}>
                  <Button
                    id="serviceDateGroup"
                    label={moment(serviceDate).format('MMM D, YYYY')}
                    rounded
                    outlined
                    className={cx(isWeekend ? 'p-button-danger' : '', 'cursor-auto')}
                    style={{ height: '22px' }}
                  />
                  <ul className="flex flex-column pl-0 gap-4">
                    {groupedRecordsByServiceDate[serviceDate].map((i, index) => (
                      <li key={`${i.id}_${index}`}>
                        {!isMobile ? (
                          <div>
                            <div className="flex col-12 p-0">
                              <div id={`previewBillingCode_${i.fee_code}`} className="col-4 p-0">
                                <div className="flex w-full" style={{ maxWidth: `${previewBillingCodeWidth}px` }}>
                                  <div style={{ width: `${codeWrapWidth}px` }}>
                                    <span className="font-bold pr-1">{`${i.fee_code} x ${(i.units * i.percent) / 100}`}</span>=
                                  </div>
                                  <div className="text-right" style={{ width: `${currencyWidth}px` }}>
                                    {`${currencyFormat(i.billed)}`}
                                  </div>
                                </div>
                              </div>

                              <div className="col-3 p-0">{!isSupplementary(speciality, invoiceType) && serviceTime(i)}</div>

                              <div className="col-4 p-0">{codes(i)}</div>

                              <div className="flex justify-content-end align-items-start col-1 p-0 mt-1">
                                <CustomCodesInfoOverlay record={i} />
                              </div>
                            </div>

                            {noteLayout(i)}

                            {duplicateLayout(i)}
                          </div>
                        ) : (
                          <div>
                            <div className="flex align-items-center justify-content-between gap-2">
                              <div id={`previewBillingCode_${i.fee_code}`} className="font-bold">
                                {`${i.fee_code} x ${(i.units * i.percent) / 100} = ${currencyFormat(i.billed)}`}
                              </div>
                              <div>{!isSupplementary(speciality, invoiceType) && serviceTime(i)}</div>
                              <CustomCodesInfoOverlay record={i} position="left" />
                            </div>

                            {codes(i)}

                            {i.note && (
                              <div id={`previewCodeNote_${i.fee_code}`} className="pt-2">
                                {i.note}
                              </div>
                            )}

                            {duplicateLayout(i)}
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>

          <Divider />

          {isMobile ? (
            localState.records.length ? (
              <div className="flex text-lg">
                <div>{`${t('Total')}: `}</div>
                {localState.records.length ? <div className="font-bold pl-2">{currencyFormat(calculateGroupTotal(localState.records))}</div> : null}
              </div>
            ) : null
          ) : (
            <div className="col-8 p-0 mt-3">
              <div className="flex col-12 p-0 font-bold">
                <div className="flex w-full" style={{ maxWidth: `${previewBillingCodeWidth}px` }}>
                  <div style={{ width: `${codeWrapWidth}px` }}>{`${t('Total')}: `}</div>
                  <div id={elementIDs.previewTotalAmount} className="text-right" style={{ width: `${currencyWidth}px` }}>
                    {localState.records.length > 0 && currencyFormat(calculateGroupTotal(localState.records))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeleplanInvoicePreview;
