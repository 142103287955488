import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Fieldset } from 'primereact/fieldset';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { checkIfStringIsDate } from '../../../utils/checkIfStringIsDate';
import { formatDate } from '../../../utils/formatDate';
import { showCompleteReport } from './helpers/showCompleteReport';
import { getEligibility, setEligibility } from '../../actions/patients.action.creators';
import { elementIDs } from '../../../config/elementIDsConfig';
import { t } from '../../../../service/localization/i18n';
import classes from './EligibilityMessage.module.scss';
import cx from 'classnames';
import moment from 'moment';

const EligibilityMessage = ({ eligibility, patient, showRefreshButton = false, getEligibilityCallback }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user.details);
  const { isFetchingPatientEligibility, checkEligibilityOnly, checkNameAndEligibility } = useSelector((state) => state.patients);
  const disabled = !patient?.PHN || !patient?.BirthDay || checkEligibilityOnly || checkNameAndEligibility;

  const onRefresh = () => {
    const params = {
      PHN: patient?.PHN,
      DOB: patient?.BirthDay ? moment(patient?.BirthDay).format('MM-DD-YYYY') : null
    };
    dispatch(
      getEligibility(params, (responseData) => {
        if (getEligibilityCallback) return getEligibilityCallback(responseData.Parsed);
        dispatch(setEligibility(responseData.Parsed));
      })
    );
  };

  const eyeExam = (
    <>
      {checkIfStringIsDate(eligibility.DATE_OF_LAST_EYE_EXAM) ? (
        <div>{`${t('Date_of_last_eye_exam')}: ${eligibility.DATE_OF_LAST_EYE_EXAM}`}</div>
      ) : (
        <div>{eligibility.DATE_OF_LAST_EYE_EXAM?.replaceAll("''", "'")}</div>
      )}
    </>
  );

  const refreshButton = (
    <Button
      id={elementIDs.refetchEligibility}
      className="p-button-outlined p-button-rounded"
      icon="pi pi-refresh"
      loading={isFetchingPatientEligibility}
      disabled={disabled}
      onClick={onRefresh}
    />
  );

  return (
    <Fieldset id={elementIDs.eligibilityMessage} className="fieldsetWithCentralizedHeader" legend={`${t('MSP_Eligibility_Report')}`}>
      {eligibility.noEligibilityReport && (
        <div className="flex align-items-center my-3">
          <div id={elementIDs.noEligibilityReportText} className="w-full text-center">
            {t('No_eligibility_report_is_available')}
          </div>

          {showRefreshButton && <div className="flex justify-content-end">{refreshButton}</div>}
        </div>
      )}

      {!eligibility.noEligibilityReport && (
        <>
          <div className="flex justify-content-between">
            <div className="my-3">
              <Tooltip target=".mspTooltip" position="right" />
              <span
                className="mspTooltip font-bold pr-2"
                data-pr-tooltip={t('Whether_or_not_the_patient_has_an_active_coverage_with_Medical_Service_Plan')}
                style={{ minWidth: showCompleteReport(user?.Speciality) ? '85px' : '45px' }}
              >
                {`${t('MSP')}:`}
              </span>

              <span id={elementIDs.elegibilityReportMSPText} className="w-full" style={{ minWidth: '150px' }}>
                {eligibility.ELIG_ON_DOS?.toUpperCase() === 'YES' && (
                  <span>
                    {`${t('Active_on')} `}
                    <span>{formatDate(eligibility.CHECK_ON_DATE)}</span>
                  </span>
                )}

                {eligibility.ELIG_ON_DOS?.toUpperCase() === 'NO' && (
                  <span>
                    {`${t('Inactive_on')} `}
                    <span>{formatDate(eligibility.CHECK_ON_DATE)}</span>
                  </span>
                )}

                {eligibility.ELIG_ON_DOS === '' && (
                  <span>
                    {`${t('Undetermined_on')} `}
                    <span>{formatDate(eligibility.CHECK_ON_DATE)}</span>
                  </span>
                )}
              </span>
            </div>

            {showRefreshButton && <div>{refreshButton}</div>}
          </div>

          <>
            {/* <div id={elementIDs.elegibilityReport23Text} className="mb-3">
                <Tooltip target=".servisePaidTooltip" position="top" />
                <span
                  className={cx('servisePaidTooltip font-bold pr-2', classes.reportRowItemWrap)}
                  data-pr-tooltip={t('Whether_or_not_MSP_might_cover_towards_the_first_ten_visits_in_a_calendar_year')}
                >
                  $23.00:
                </span>

                {eligibility.SERVICES_PAID ? (
                  <span className={eligibility.SERVICES_PAID === 10 ? 'p-error' : ''}>
                    {String.format(t('MSP_visits_paid_to_date'), eligibility.SERVICES_PAID)}
                  </span>
                ) : (
                  <span className="p-message-error">{eligibility.PATIENT_VISIT_CHARGE}</span>
                )}
              </div> */}

            <div id={elementIDs.eyeExamWrap} className="mb-3">
              <Tooltip target=".eyeExamTooltip" position="top" />
              <span
                className={cx('eyeExamTooltip font-bold pr-2', classes.reportRowItemWrap)}
                data-pr-tooltip={t('Last_covered_eye_exam_as_reported_by_MSP_eligibility_system')}
              >
                {`${t('Eye_Exam').toUpperCase()}:`}
              </span>
              <span>{eligibility.DATE_OF_LAST_EYE_EXAM ? eyeExam : 'No report'}</span>
            </div>
          </>

          <div id={elementIDs.elegibilityReportPremiumAssistanceStatusText}>
            <span className="font-bold pr-2">{`${t('Premium_assistance_status')} ($23.00):`}</span>

            {eligibility.SERVICES_PAID ? (
              <span id={elementIDs.servicePaidWrap} className={eligibility.SERVICES_PAID === 10 ? 'p-error' : ''}>
                {String.format(t('MSP_visits_paid_to_date'), eligibility.SERVICES_PAID)}
              </span>
            ) : (
              <span
                id={elementIDs.servicePaidWrap}
                className={eligibility.PATIENT_VISIT_CHARGE?.toUpperCase() === 'THIS IS NOT AN INSURED BENEFIT' ? 'p-error' : ''}
              >
                {eligibility.PATIENT_VISIT_CHARGE}
              </span>
            )}
          </div>
        </>
      )}
    </Fieldset>
  );
};

export default EligibilityMessage;
