import React from 'react';
import RecordsDataTable from '../TeleplanInvoiceRecords/RecordsDataTable';
import MobileRecordsView from '../TeleplanInvoiceRecords/MobileRecordsView';

const StepTwo = () => {
  if (process.env.REACT_APP_CYPRESS) return <RecordsDataTable />;

  return (
    <>
      <div className="hidden lg:block">
        <RecordsDataTable />
      </div>
      <div className="block lg:hidden">
        <MobileRecordsView />
      </div>
    </>
  );
};

export default StepTwo;
