/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import { Divider } from 'primereact/divider';
import Demographics from './Demographics';
import Address from './Address';
import Success from './Success';
import CommonCard from '../../../common/components/CommonCard';
import CircularProgress from '../../../../components/Misc/Loader/CircularProgress/CircularProgress';

import { integersOnlyCheckForString, validateCanadianZip } from '../../../regex/regex';
import { newPatientBlankRecord } from './helpers/newPatientBlankRecord';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { setFormikFocusToInvalidInput } from '../../../utils/setFormikFocusToInvalidInput';
import { provinces_list } from '../../../common/contants';
import { t } from '../../../../service/localization/i18n';
import classes from './PatientForm.module.scss';
import moment from 'moment';
import cx from 'classnames';
import * as yup from 'yup';
import { isString, isEmpty } from 'lodash';
import {
  createPatient,
  setShowNewPatientSuccessPage,
  setEligibility,
  duplicatePatientCheck,
  setPatientInfo,
  setActiveTabIndexForPatientForm,
  editPatient
} from '../../actions/patients.action.creators';
import { routes } from '../../../../routes/routes';
import DuplicatePHNDialog from '../commonElements/DuplicatePHNDialog';
import DuplicatePatientsDialog from '../commonElements/DuplicatePatientsDialog';
import { formatPatientInfoForClaims } from '../../helpers/formatPatientInfoForClaims';
import { commonInputs } from '../../../config/commonInputsConfig';
import {
  getCodesByType,
  setPatientPickerQueryValue,
  updatePrivateRecord,
  updateTeleplanInvoice,
  updateWSBCRecord
} from '../../../claims/actions/claims.action.creators';
import { phoneNumberLength } from '../../../config/defaultValuesConfig';
import { addZerosToPhoneNumber } from '../../../utils/addZerosToPhoneNumber';
import { initialAddressType, initialEmailType, initialPhoneType } from './helpers/initialValuesForPatientForm';
import { onBillToUpdate } from '../../../claims/components/PrivateDetails/claimDetailsHelpers/onClaimUpdate';
import { elementIDs } from '../../../config/elementIDsConfig';
import { inputs as wsbcInputs } from '../../../claims/components/WSBCReport/helpers/inputs';
import { inputs as teleplanInputs } from '../../../claims/components/TeleplanInvoice/helpers/inputs';
import { useURLQuery } from '../../../hooks/useURLQuery';
import { patientFullNameWithAge } from '../../../utils/patientFullName';

const PatientForm = ({ showInCard = true, showPrompt = true, isEdit, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = useURLQuery();
  const { DCNGuid } = useSelector((state) => state.user.details);
  const { teleplanInvoice, wsbcRecord, patientPickerQueryValue } = useSelector((state) => state.claims);
  const { isMobile, isMobileOnly, iPadInPortrait, isLandscape } = useSelector((state) => state.core.window);
  const {
    patient_details,
    isFetchingPatients,
    eligibility,
    isCreatingPatient,
    isSavingPatientDetails,
    showNewPatientSuccessPage,
    checkingPatientDuplicates,
    patientFormActiveTabIndex,
    isFetchingPHNValidation
  } = useSelector((state) => state.patients);

  const phnRef = useRef(null);
  const [step, setStep] = useState(0);
  const [isPHNValid, setIsPHNValid] = useState(true);
  const [isDateValid, setIsDateValid] = useState(true);
  const [isPHNDuplicate, setIsPHNDuplicate] = useState(false);
  const [showPatientDuplicateDialog, setShowPatientDuplicateDialogAction] = useState(false);
  const [showPHNDuplicateDialog, setShowPHNDuplicateDialogAction] = useState(false);
  const [displayPrompt, setDisplayPrompt] = useState(true);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [isPHNFocused, setIsPHNFocused] = useState(false);
  const [duplicatedPatients, setDuplicatedPatients] = useState([]);
  const [patientDetails, setPatientDetails] = useState(null);
  const isNew = location?.pathname?.indexOf('new') > -1;
  const isNewborn = location?.pathname?.includes(routes.createNewborn.path);

  const addNewPatientToNewClaim =
    props.showInDialog ||
    !showNewPatientSuccessPage.showSuccessPage ||
    (showNewPatientSuccessPage.showSuccessPage && showNewPatientSuccessPage.from === 'teleplanClaim');
  const disabledPHN = !isNew && patientDetails?.PHNProvince === 'BC' && patientDetails?.TotalRecords > 0;

  const dataForSubmit = (data) => {
    const mrp = data?.MRP?.length ? data?.MRP[0] : null;
    const result = {
      ...data,
      FirstName: data.FirstName?.trim()?.toUpperCase(),
      LastName: data.LastName?.trim()?.toUpperCase(),
      MidName: data.MidName?.trim()?.toUpperCase(),
      MRPNumber: mrp?.value || '',
      MRPName: mrp?.name || '',
      DateDeceased: data?.DateDeceased ? moment(data.DateDeceased).format('YYYY-MM-DD') : null
    };

    delete result.MRP; // Remove the MRP property

    return result;
  };

  const onSubmit = (formData, checkDuplicate = true) => {
    const data = dataForSubmit(formData);
    // Check if the PHNProvince property of the data object is "Institutional"
    const isInstitutional = data?.PHNProvince === 'Institutional';
    data.AccessWord = data?.AccessWord?.toLowerCase() || ''; // BE requrement convert AccessWord to lowercase

    // If the PHNProvince is not "Institutional"
    if (!isInstitutional) {
      // Remove leading zeros from the PHN property of the data object
      // VER-440
      data.PHN = data.PHN?.replace(/^0+/, '');
    }

    // Create new patient
    if (isNew && !isEdit) {
      if (!checkingPatientDuplicates) {
        // [KS] CMO-640 - Validate BC PHN number on the New patient profile screen
        // [KS] CMO-641 - Show error message if the DOB is invalid on New Patient screen
        if ((!isPHNValid && data?.PHN?.length) || !isDateValid) {
          dispatch(setToastMessage({ type: 'warn', message: t('Please_correct_invalid_entries') }));
        } else {
          if (!data?.PHN?.length && checkDuplicate) {
            checkPatientDuplicate({ callback: () => createNewPatient(data) });
          } else {
            createNewPatient(data);
          }
        }
      }
    }

    // Edit patient
    if (!isNew || isEdit) {
      if (!checkingPatientDuplicates) {
        const FullName = data?.MidName
          ? `${data?.LastName?.toUpperCase()}, ${data?.FirstName?.toUpperCase()} ${data?.MidName?.toUpperCase()}`
          : `${data?.LastName?.toUpperCase()}, ${data?.FirstName?.toUpperCase()}`;

        const newData = {
          ...data,
          FirstName: data.FirstName?.toUpperCase(),
          LastName: data.LastName?.toUpperCase(),
          MidName: data.MidName?.toUpperCase(),
          FullName,
          Province: isString(data?.Province)
            ? data?.Province?.length === 2
              ? provinces_list.find((i) => i.value === data?.Province?.toUpperCase())?.value
              : provinces_list.find((i) => i.label === data?.Province)?.value
            : data?.Province?.value
        };

        // delete newData.EligibilityInfo;

        // [KS] CMO-640 - Validate BC PHN number on the New patient profile screen
        // [KS] CMO-641 - Show error message if the DOB is invalid on New Patient screen
        if ((!isPHNValid && data?.PHN?.length) || !isDateValid) {
          dispatch(setToastMessage({ type: 'warn', message: t('Please_correct_invalid_entries') }));
        } else {
          if (!newData?.PHN?.length && checkDuplicate) {
            checkPatientDuplicate({
              callback: () =>
                dispatch(
                  editPatient(newData, () => {
                    formik.resetForm();
                    dispatch(setToastMessage({ type: 'success', message: t('Your_changes_are_saved') }));
                    if (props.showInDialog) {
                      props.onEdit(newData);
                    } else {
                      navTo();
                    }
                  })
                )
            });
          } else {
            dispatch(
              editPatient(newData, () => {
                dispatch(setToastMessage({ type: 'success', message: t('Your_changes_are_saved') }));
                formik.resetForm();
                if (props.showInDialog) {
                  props.onEdit(newData);
                } else {
                  navTo();
                }
              })
            );
          }
        }
      }
    }
  };

  const createNewPatient = (data) => {
    dispatch(
      createPatient(data, (responseData) => {
        if (props.showInDialog) return props.onCreate(responseData);

        if (
          (showNewPatientSuccessPage.showSuccessPage && showNewPatientSuccessPage.from === 'teleplanClaim' && !isEmpty(eligibility)) ||
          (showNewPatientSuccessPage.showSuccessPage && !showNewPatientSuccessPage.from)
        ) {
          //show success page
          dispatch(setPatientInfo(responseData));
          setPatientDetails(responseData);
          setStep(1);
        } else {
          //do not show success page
          setDisplayPrompt(false);
          addNewPatientForNewClaim(responseData); // Set new patient to private claim form
          dispatch(setShowNewPatientSuccessPage({ showSuccessPage: true, from: '' }));
          dispatch(setToastMessage({ type: 'success', message: t('Patient_created_successfully') }));
          navTo();
        }

        formik.resetForm();
        showPatientDuplicateDialog && setShowPatientDuplicateDialog(false);
      })
    );
  };

  const setShowPHNDuplicateDialog = (showPHNDialog) => {
    setShowPHNDuplicateDialogAction(showPHNDialog);
    setDisplayPrompt(!showPHNDialog);
  };

  const setShowPatientDuplicateDialog = (showDuplicateDialog) => {
    setShowPatientDuplicateDialogAction(showDuplicateDialog);
    setDisplayPrompt(!showDuplicateDialog);
  };

  const handleExistingProfile = (patient) => {
    if (props.showInDialog) return props.onCreate(patient);
    const navToPatientProfile = () => {
      dispatch(setPatientInfo(patient));
      history.replace(`${routes.patientDetails.path}/${patient.PatientGuid}`);
    };

    if (!isNew) return navToPatientProfile(); // redirect to patient profile

    if (isNew) {
      if (!addNewPatientToNewClaim) return navToPatientProfile(); // redirect to patient profile

      // redirect to create new claim form
      if (addNewPatientToNewClaim) {
        const redirectToCreateClaimForm = (responseData) => {
          addNewPatientForNewClaim(responseData);
          navTo();
        };
        // if patient is archived
        if (patient.Status === -1) {
          dispatch(editPatient({ ...patient, Status: 0 }, (responseData) => redirectToCreateClaimForm(responseData)));
        } else {
          // if patient is NOT archived
          redirectToCreateClaimForm(patient);
        }
      }
    }
  };

  const getInitialValuesForEdit = async () => {
    let MRP = [];
    if (patient_details.MRPNumber) {
      const result = await getCodesByType({ codeType: commonInputs.referral.codeType, query: patient_details.MRPNumber });
      MRP = result;
    }

    const PHNInit = patient_details.PHN?.length > 0 && patient_details.PHN !== 'N/A' ? patient_details.PHN : '';
    const phoneInit =
      patient_details?.Phone?.length === phoneNumberLength
        ? patient_details.Phone
        : patient_details?.Phone?.length > 0 && patient_details?.Phone?.length < phoneNumberLength
        ? addZerosToPhoneNumber(patient_details?.Phone)
        : patient_details?.Phone;
    const phoneTypeInit = patient_details.PhoneType ? patient_details.PhoneType : initialPhoneType;
    const emailTypeInit = patient_details.EmailType ? patient_details.EmailType : initialEmailType;
    const addressTypeInit = patient_details.AddressType ? patient_details.AddressType : initialAddressType;

    const initValues = {
      ...patient_details,
      PHN: PHNInit,
      BirthDay: moment(patient_details?.BirthDay).format('MM/DD/YYYY') || '', // [KS] CMO-1287 - Edit patient - demographicsFormLayout - incorrect display of date of birth
      Phone: phoneInit,
      PhoneType: phoneTypeInit,
      EmailType: emailTypeInit,
      AddressType: addressTypeInit,
      Zip: patient_details?.Zip || '',
      MRP,
      DateDeceased: patient_details.DateDeceased ? moment(patient_details.DateDeceased).toDate() : null
    };

    return initValues;
  };

  const formikInitValues = async () => {
    // New patient
    if (isNew) return newPatientBlankRecord(DCNGuid, patientPickerQueryValue);

    // Edit patient
    if (!isNew || isEdit) {
      const initData = await getInitialValuesForEdit();
      setPatientDetails(initData);
      return initData;
    }
  };

  const validationSchema = yup.object().shape({
    BirthDay: yup
      .date()
      .min('01/01/1900', `${t('Year_minimum_value_is')} 1900`)
      .max(new Date(), `${t('Year_maximum_value_is')} ${moment().year()}`)
      .required(t('Mandatory_field.1'))
      .nullable(),
    Sex: yup.string().required(t('Mandatory_field.1')),
    LastName: yup.string().required(t('Mandatory_field.1')),
    FirstName: yup.string().required(t('Mandatory_field.1')),
    Email: yup.string().email(t('Invalid_entry')).nullable(),
    Zip: yup.string().matches(validateCanadianZip, t('Invalid_entry')),
    NewbornBD: yup
      .date()
      .nullable()
      .when('IsNewborn', {
        is: true,
        then: yup
          .date()
          .min('01/01/1900', `${t('Year_minimum_value_is')} 1900`)
          .max(new Date(), `${t('Year_maximum_value_is')} ${moment().year()}`)
          .required(t('Mandatory_field.1'))
          .nullable(),
        otherwise: yup.date().nullable()
      }),
    IsNewborn: yup.boolean()
  });

  const formik = useFormik({
    enableReinitialize: true,
    // validateOnChange: false,
    // validateOnBlur: true,
    initialValues: isNew && newPatientBlankRecord(DCNGuid, patientPickerQueryValue),
    validationSchema,
    onSubmit
  });

  // Formik initial values
  useEffect(() => {
    const fetchData = async () => {
      const initialValues = await formikInitValues();
      formik.resetForm({ values: initialValues });
    };

    fetchData();
  }, []);

  // Set initial PHN or LastName value from patientPickerQueryValue
  useEffect(() => {
    if (patientPickerQueryValue) {
      const isPHNNumbersOnly = integersOnlyCheckForString.test(patientPickerQueryValue);
      if (isPHNNumbersOnly) {
        formik.setFieldValue('PHN', patientPickerQueryValue);
      } else formik.setFieldValue('LastName', patientPickerQueryValue?.toUpperCase());
    }
  }, [patientPickerQueryValue]);

  //initial provinces list for create new patient
  useEffect(() => {
    //Reset eligibility message
    isMobile && window[`scrollTo`]({ top: 0 });
    if (isNew) dispatch(setEligibility({}));
  }, []);

  //scroll to first invalid field
  useEffect(() => {
    setFormikFocusToInvalidInput(formik);
  }, [formik]);

  //component unmount
  useEffect(() => {
    return () => {
      //Reset eligibility message
      dispatch(setEligibility({}));
      dispatch(setActiveTabIndexForPatientForm(0));
      dispatch(setShowNewPatientSuccessPage({ showSuccessPage: true, from: '' }));
      patientPickerQueryValue && dispatch(setPatientPickerQueryValue('')); // CMO-1365 - New Teleplan claim->Patient field->type in PHN->No matches->Carry PHN from Patient field into PHN field on the Add patient screen
    };
  }, []);

  const addNewPatientForNewClaim = (patient) => {
    const formatted = formatPatientInfoForClaims(patient);

    if (!isEmpty(patient)) {
      switch (showNewPatientSuccessPage?.from) {
        case 'teleplanClaim':
          dispatch(
            updateTeleplanInvoice({
              ...teleplanInvoice,
              formState: {
                ...teleplanInvoice.formState,
                [teleplanInputs.patient.name]: [...teleplanInvoice.formState[teleplanInputs.patient.name], formatted]
              }
            })
          );
          break;

        case 'privateClaim':
          dispatch(updatePrivateRecord({ [commonInputs.patients.descriptionName]: [formatted] }));
          onBillToUpdate([formatted], dispatch); // Add Bill To value
          break;

        case 'WSBCReport':
          dispatch(
            updateWSBCRecord({
              ...wsbcRecord,
              resetWSBCR: true, // CMO-2317 - Cach is not cleaned on new Teleplan when the new patient is created from that screen
              formState: { ...wsbcRecord.formState, [wsbcInputs.patient.name]: [formatted] }
            })
          );
          break;

        default:
          break;
      }
    }
  };

  const checkPatientDuplicate = ({ onPHNChange = false, callback, patientData }) => {
    let partialPatientDetails = {
      DCNGuid: formik.values.DCNGuid,
      PHN: patientData?.PHN || formik.values.PHN,
      PHNProvince: patientData?.PHNProvince || formik.values.PHNProvince,
      FirstName: formik.values.FirstName,
      MidName: formik.values.MidName,
      LastName: formik.values.LastName,
      FullName: formik.values.FullName,
      Sex: formik.values.Sex,
      DOB: formik.values.BirthDay
    };

    if (!isNew) partialPatientDetails = { ...partialPatientDetails, PatientGuid: formik.values.PatientGuid };

    dispatch(
      duplicatePatientCheck(partialPatientDetails, (responseData) => {
        if (responseData?.length) {
          setDuplicatedPatients(responseData);
          if (onPHNChange) {
            // on PHN update
            setShowPHNDuplicateDialog(true);
            setDisableSaveButton(true); // [KS] CMO-1406 - Create new patient - error 500 (check comments)
            setIsPHNDuplicate(true); // [KS] CMO-1406 - Create new patient - error 500 (check comments)
          } else {
            // on "Create"/"Save" click
            setShowPatientDuplicateDialog(true);
          }
        } else {
          setDisableSaveButton(false); // [KS] CMO-1406 - Create new patient - error 500 (check comments)
          setIsPHNDuplicate(false); // [KS] CMO-1406 - Create new patient - error 500 (check comments)
          if (!onPHNChange) {
            // on "Create"/"Save" click
            // if no duplicate
            callback();
          }
        }
      })
    );
  };

  const onExit = () => {
    if (props.onClose) return props.onClose();
    formik.resetForm();
    navTo();
  };

  const promptMessage = () => {
    return JSON.stringify({ isDirty: formik.dirty });
  };

  const onHideDuplicatePatientsDialog = () => {
    setShowPatientDuplicateDialog(false);
    setDuplicatedPatients([]);
  };

  const onHideDuplicatePHNDialog = () => {
    setShowPHNDuplicateDialog(false);
    phnRef?.current?.focus();
  };

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small id={`${elementIDs.mandatoryField}_${name}`} className="p-error">
          {formik.errors[name]}
        </small>
      )
    );
  };

  const onClose = () => {
    if (props.onClose) return props.onClose();
    return navTo();
  };

  const navTo = () => {
    query.get('replaceTo') ? history.replace(query.get('replaceTo')) : history.goBack();
  };

  const demographicsFormLayout = (
    <Demographics
      isNew={isNew}
      isNewborn={isNewborn}
      phnRef={phnRef}
      formik={formik}
      isDateValid={isDateValid}
      isPHNValid={isPHNValid}
      isPHNDuplicate={isPHNDuplicate}
      disabledPHN={disabledPHN}
      setIsPHNDuplicate={setIsPHNDuplicate}
      setShowPHNDuplicateDialog={setShowPHNDuplicateDialog}
      setIsDateValid={setIsDateValid}
      setIsPHNValid={setIsPHNValid}
      setIsPHNFocused={setIsPHNFocused}
      isFormFieldValid={isFormFieldValid}
      getFormErrorMessage={getFormErrorMessage}
      checkPatientDuplicate={checkPatientDuplicate}
    />
  );

  const addressFormLayout = <Address formik={formik} isFormFieldValid={isFormFieldValid} getFormErrorMessage={getFormErrorMessage} />;

  // Action buttons
  const actionButtons = (
    <div
      className={cx('flex col-12 align-items-end px-0 py-3 stickyActionButtons', isMobile ? '' : 'mt-auto mb-0')}
      style={{ marginBottom: isMobile ? '' : '16px' }}
    >
      <div className={cx('field flex col-12 p-0')}>
        {formik.dirty ? (
          <>
            <Button
              form="patient-form"
              id={isNew ? elementIDs.createNewPatient : elementIDs.saveChanges}
              className="mr-2"
              type="submit"
              loading={isCreatingPatient || isSavingPatientDetails}
              disabled={
                isPHNFocused || // VER-617 - [OB] I suggest that we show that Save & Close is disabled as long as the cursor is in PHN
                isFetchingPHNValidation ||
                checkingPatientDuplicates ||
                !isDateValid ||
                (!isNew && disableSaveButton && formik.values.PHN?.length > 0)
              }
              label={isNew ? t('Create_Record') : t('Save_and_Close')}
            />
            <Button
              id={!formik.dirty && (iPadInPortrait || isMobileOnly) ? elementIDs.close : elementIDs.cancel}
              className="p-button-outlined ml-2"
              type="button"
              label={!formik.dirty && (iPadInPortrait || isMobileOnly) ? t('Close') : t('Cancel')}
              onClick={onClose}
            />
          </>
        ) : (
          <Button id={elementIDs.close} type="button" label={t('Close')} onClick={onClose} />
        )}
      </div>
    </div>
  );

  const headerCloseButton = (
    <div className={classes.headerContentLeft}></div>
    // <Button
    //   className="p-button-text p-button-rounded"
    //   type="button"
    //   icon="pi pi-times"
    //   onClick={() => navTo()}
    // />
  );

  const headerContentLeft = <div className={classes.headerContentLeft}></div>;

  const patientsName = () => {
    return patientFullNameWithAge(patient_details);
  };

  const cardTitle = isNew ? t('New_Patient') : isMobileOnly && !isLandscape ? t('Edit_Profile') : `${t('Edit_Profile')}: ${patientsName()}`;

  const dialogLayout = (
    <>
      {!process.env.REACT_APP_CYPRESS && (
        <TabView
          className="createNewPatientFormTabView block md:hidden"
          activeIndex={patientFormActiveTabIndex}
          onTabChange={(e) => dispatch(setActiveTabIndexForPatientForm(e.index))}
        >
          <TabPanel header={t('Profile')}>
            <div className="grid mt-1">{demographicsFormLayout}</div>
          </TabPanel>

          <TabPanel id="testId" header={t('Address')}>
            <div className="grid mt-1">{addressFormLayout}</div>
          </TabPanel>
        </TabView>
      )}

      <div className="hidden md:flex">
        <div className={cx('flex flex-column')} style={{ width: '500%' }}>
          <div className="mb-2">{t('Demographics')}</div>
          <div className="flex flex-column justify-content-between h-full">
            <div className="grid mt-1">{demographicsFormLayout}</div>
          </div>
        </div>

        <Divider layout="vertical" />

        <div className={cx('flex flex-column')} style={{ width: '500%' }}>
          <div className="mb-2">{t('Address')}</div>
          <div className="grid mt-1">{addressFormLayout}</div>
          {actionButtons}
        </div>
      </div>
    </>
  );

  const pageLayout = (
    <>
      {isMobile ? (
        <TabView
          className="createNewPatientFormTabView"
          activeIndex={patientFormActiveTabIndex}
          onTabChange={(e) => dispatch(setActiveTabIndexForPatientForm(e.index))}
        >
          <TabPanel header={t('Profile')}>
            <div className="grid mt-1">{demographicsFormLayout}</div>
          </TabPanel>

          <TabPanel id="testId" header={t('Address')}>
            <div className="grid mt-1">{addressFormLayout}</div>
          </TabPanel>
        </TabView>
      ) : (
        <div className="flex">
          <div className={cx('flex flex-column', classes.wrapForLeftAndRightParts)}>
            <div className="mb-2">{t('Demographics')}</div>
            <div className="flex flex-column justify-content-between h-full">
              <div className="grid mt-1">{demographicsFormLayout}</div>
            </div>
          </div>

          <Divider className={classes.verticalDevider} layout="vertical" />

          <div className={cx('flex flex-column', classes.wrapForLeftAndRightParts)}>
            <div className="mb-2">{t('Address')}</div>
            <div className="grid mt-1">{addressFormLayout}</div>
            {actionButtons}
          </div>
        </div>
      )}
    </>
  );

  const formLayout = (
    <form id="patient-form" onSubmit={formik.handleSubmit} className="p-fluid mt-2">
      <div className="col-12 p-0">
        {isMobileOnly && !isLandscape && !isNew && <div className="flex mb-3 justify-content-center">{patientsName()}</div>}
      </div>

      {props.showInDialog ? dialogLayout : pageLayout}

      {/* Mobile view action buttons */}
      {isMobile && actionButtons}
    </form>
  );

  const formWrap = () => {
    if (!showInCard) return formLayout;

    return (
      <CommonCard
        title={cardTitle}
        titleClassName="flex align-items-center justify-content-center pb-4"
        rootClassName={isMobile ? classes.newPatientFormMobile : classes.newPatientFormDesktop}
        // headerActionButton={headerCloseButton}
        // headerContentLeft={isMobileOnly && !isLandscape && headerContentLeft} // align card title at the center on mobile devices
      >
        {formLayout}
      </CommonCard>
    );
  };

  const whenPrompt = showPrompt && formik.dirty && displayPrompt;

  if (!formik.values) return <CircularProgress />;

  return (
    <div id={isNew ? elementIDs.createPatientRootContainer : elementIDs.editPatientRootContainer}>
      <Prompt when={whenPrompt} message={promptMessage} />

      {isFetchingPatients && <CircularProgress />}

      {step === 0 && <div className="flex justify-content-center contentOutsidePaddings">{formWrap()}</div>}

      {step === 1 && (
        <Success
          patientDetails={patientDetails}
          eligibility={eligibility}
          showNewPatientSuccessPage={showNewPatientSuccessPage}
          setStep={setStep}
          addNewPatientForNewClaim={addNewPatientForNewClaim}
        />
      )}

      <DuplicatePHNDialog
        visible={showPHNDuplicateDialog}
        duplicatedPatientsData={duplicatedPatients[0]}
        addNewPatientToNewClaim={addNewPatientToNewClaim}
        handleExistingProfile={handleExistingProfile}
        onHide={onHideDuplicatePHNDialog}
        onExit={onExit}
      />

      <DuplicatePatientsDialog
        visible={showPatientDuplicateDialog}
        addNewPatientToNewClaim={addNewPatientToNewClaim}
        duplicatedPatientsList={duplicatedPatients}
        onHide={onHideDuplicatePatientsDialog}
        onSubmit={() => onSubmit(formik.values, false)}
        handleExistingProfile={handleExistingProfile}
      />
    </div>
  );
};

export default PatientForm;
