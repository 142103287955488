import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { invoiceTypes } from '../helpers/defaultValues';
import { inputs } from '../helpers/inputs';
import { isSupplementary } from '../../../../config/specialitiesConfig';
import { hideEmergency } from '../helpers/inputsConditions';
import { t } from '../../../../../service/localization/i18n';

export const useQuickPickItems = () => {
  const { isMobile } = useSelector((state) => state.core.window);
  const { control, pos, isGroup, isNew, localState } = useFormContext();
  const speciality = useWatch({ control, name: inputs.speciality.name });
  const invoiceType = useWatch({ control, name: inputs.payor.name });
  const showFeeTab = !isSupplementary(speciality, invoiceType);
  const showDxTab = !localState.isEmptyPractitioner;
  const showReferralTab = !isSupplementary(speciality, invoiceType);
  const showEmergencyDevider = !isSupplementary(speciality, invoiceType);
  const hideEmergencySection = hideEmergency({ isGroup, isNew, speciality, invoiceType });
  const indexForFee = showFeeTab && (isGroup || pos) ? 4 : 3;
  const emergencyDeviderIndex = isGroup || pos ? 3 : 2;
  const isWSBC = invoiceType === invoiceTypes.wsbc;

  const getQuikPickItems = () => {
    let commonQuickPickItems = [
      {
        name: inputs.serviceDate.name,
        label: inputs.serviceDate.label,
        codeType: inputs.serviceDate.codeType,
        openCatalogButton: false,
        showDivider: true,
        isCodePicker: true,
        showServiceDateButton: true,
        showDateAssistButton: true
      },

      {
        name: inputs.refToBy.name
      }
    ];

    if (localState.isEmptyPractitioner) return commonQuickPickItems;

    const emergencyDevider = {
      name: inputs.emergency.name,
      label: !hideEmergencySection ? (isWSBC ? t('Expedited') : t('OOFHP')) : false,
      showDivider: true
    };

    const patient = {
      name: inputs.patient.name,
      label: inputs.patient.label,
      showDivider: true
    };

    const aoi = {
      name: inputs.aoi.name,
      label: inputs.aoi.label,
      codeType: inputs.aoi.codeType,
      codeDescription: inputs.aoi.codeDescription,
      maxEntries: inputs.aoi.maxEntries,
      openCatalogButton: true,
      searchAllType: 'aoi_all',
      showSwitchButton: false,
      showDivider: true,
      isCodePicker: true
    };

    const noi = {
      name: inputs.noi.name,
      label: inputs.noi.label,
      codeType: inputs.noi.codeType,
      codeDescription: inputs.noi.codeDescription,
      maxEntries: inputs.noi.maxEntries,
      openCatalogButton: true,
      searchAllType: 'noi_all',
      showSwitchButton: false,
      showDivider: true,
      isCodePicker: true
    };

    const fee = {
      name: inputs.feeCodes.name,
      label: inputs.feeCodes.label,
      codeType: inputs.feeCodes.codeType,
      codeDescription: inputs.feeCodes.codeDescription,
      maxEntries: inputs.feeCodes.maxEntries,
      openCatalogButton: true,
      searchAllType: 'fee_all',
      showSwitchButton: false,
      showDivider: !isGroup,
      isCodePicker: true
    };

    const dx = {
      name: inputs.icd9.name,
      label: inputs.icd9.label,
      codeType: inputs.icd9.codeType,
      codeDescription: inputs.icd9.codeDescription,
      maxEntries: inputs.icd9.maxEntries,
      openCatalogButton: true,
      searchAllType: 'icd9_all',
      showSwitchButton: false,
      showDivider: true,
      isCodePicker: true
    };

    const referral = {
      name: inputs.referral.name,
      label: inputs.referral.tabLabel,
      codeType: inputs.referral.codeType,
      codeDescription: inputs.referral.codeDescription,
      maxEntries: inputs.referral.maxEntries,
      searchAllType: 'referral_all',
      openCatalogButton: true,
      showSwitchButton: true,
      showDivider: false,
      isCodePicker: true
    };

    const anatomicCode = {
      name: inputs.anatomicCode.name,
      label: inputs.anatomicCode.label,
      showDivider: true
    };

    if (pos || isGroup || isMobile) commonQuickPickItems.splice(0, 0, patient);
    if (showEmergencyDevider) commonQuickPickItems.splice(emergencyDeviderIndex, 0, emergencyDevider);
    if (invoiceType === invoiceTypes.wsbc) commonQuickPickItems.splice(indexForFee, 0, anatomicCode);
    if (showDxTab) commonQuickPickItems.splice(indexForFee, 0, dx);
    if (showFeeTab) commonQuickPickItems.splice(indexForFee, 0, fee);
    if (invoiceType === invoiceTypes.wsbc) commonQuickPickItems.splice(2, 0, ...[aoi, noi]);
    if (showReferralTab) commonQuickPickItems = [...commonQuickPickItems, referral];

    // Do not show Dx quick pick for Teleplan Group form
    if (isGroup) commonQuickPickItems = commonQuickPickItems.filter((i) => i.name !== inputs.icd9.name && i.name !== inputs.referral.name);

    return commonQuickPickItems;
  };

  return { quickPickItems: getQuikPickItems() };
};
