import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';

import { addEClaim, addNewPrivate, addNewTeleplan, addNewWSBCR } from '../../../modules/claims/helpers/addNewInvoice';
import { setClaimFilters } from '../../../modules/claims/actions/claims.action.creators';
import { claimsDefaultFilters } from '../../../modules/config/defaultValuesConfig';
import { patientFullNameWithAge } from '../../../modules/utils/patientFullName';
import { elementIDs } from '../../../modules/config/elementIDsConfig';
import { routes } from '../../../routes/routes';
import { t } from '../../../service/localization/i18n';
import { showPolicy } from '../../../modules/config/specialitiesConfig';

const SuccessActionButtons = ({
  wsbcReportWithSamePatient,
  wsbcReportWithOtherPatient,
  billSameTeleplanPatient,
  billSameTeleplanPatientPOS,
  billSameTeleplanPatientGroup,
  billSamePrivatePatient,
  billOtherTeleplanPatient,
  billOtherTeleplanPatientPOS,
  billOtherTeleplanGoupPatient,
  billOtherPrivatePatient,
  billSameEClaim,
  openPatientProfile,
  firstVisit,
  goToPrevPage,
  newPatient,
  patientsList
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);
  const { showNewTeleplanButtons } = useSelector((state) => state.core.window);
  const showEClaimButton = showPolicy();

  const handleFirstVisit = () => {
    if (firstVisit) return firstVisit();
    return addNewTeleplan({ history, replaceRoute: true, firstVisit: true });
  };

  const handleBillSameTeleplanPatient = () => {
    if (billSameTeleplanPatient) return billSameTeleplanPatient();
    return addNewTeleplan({ history, patient_details: patientsList[0], replaceRoute: true, pos: false });
  };

  const handleBillSameTeleplanPatientPOS = () => {
    if (billSameTeleplanPatientPOS) return billSameTeleplanPatientPOS();
    return addNewTeleplan({ history, patient_details: patientsList[0], replaceRoute: true, pos: true });
  };

  const handleBillOtherTeleplanPatient = () => {
    if (billOtherTeleplanPatient) return billOtherTeleplanPatient();
    return history.replace(routes.newClaimRecord.path);
  };

  const handleBillOtherTeleplanPatientPOS = () => {
    if (billOtherTeleplanPatientPOS) return billOtherTeleplanPatientPOS();
    return history.replace(routes.teleplanPOS.path);
  };

  const handleBillSamePrivatePatient = () => {
    if (billSamePrivatePatient) return billSamePrivatePatient();
    return addNewPrivate({ history, patient_details: patientsList[0], replaceRoute: true });
  };

  const handleBillEClaimForSamePatient = () => {
    if (billSameEClaim) return billSameEClaim();
    return addEClaim({ history, patientGuid: patientsList[0]?.PatientGuid, replaceRoute: true });
  };

  const handleBillOtherPrivatePatient = () => {
    if (billOtherPrivatePatient) return billOtherPrivatePatient();
    return addNewPrivate({ history, replaceRoute: true });
  };

  const handleWSBCReportWithSamePatient = () => {
    if (wsbcReportWithSamePatient) return wsbcReportWithSamePatient();
    return addNewWSBCR({ history, patient_details: patientsList[0], replaceRoute: true });
  };

  const handleWSBCReportWithOtherPatient = () => {
    if (wsbcReportWithOtherPatient) return wsbcReportWithOtherPatient();
    return addNewWSBCR({ history, replaceRoute: true });
  };

  const handleBillSameTeleplanPatientGroup = () => {
    if (billSameTeleplanPatientGroup) return billSameTeleplanPatientGroup();
    return addNewTeleplan({ history, patient_details: patientsList[0], replaceRoute: true, group: true });
  };

  const handleBillOtherTeleplanGoupPatient = () => {
    if (billOtherTeleplanGoupPatient) return billOtherTeleplanGoupPatient();
    return history.replace(routes.teleplanGroup.path);
  };

  const handleOpenPatientProfile = (PatientGuid) => {
    if (openPatientProfile) return openPatientProfile(PatientGuid);

    dispatch(setClaimFilters(claimsDefaultFilters));
    return history.replace(`${routes.patientDetails.path}/${PatientGuid}`);
  };

  const handleNewPatient = () => {
    if (newPatient) return newPatient();
    return history.replace(`${routes.createPatient.path}/new`);
  };

  const handleGoToPrevPage = () => {
    if (goToPrevPage) return goToPrevPage();
    return history.goBack();
  };

  return (
    <div className="w-full">
      <div className="buttonsSectionsWrap flex flex-wrap justify-content-center gap-0  md:gap-4">
        {/* BILL SAME PATIENT */}
        <div className="buttonsSections card w-22rem">
          <div className="text-xl mb-3 font-semibold" style={{ textAlign: 'center', color: '#333', fontSize: '18px' }}>
            {t('Bill_same_patients')}
          </div>

          <div className="buttonsGap" style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
            {user.canBillMSP && (
              <>
                <Button
                  id={elementIDs.billSameTeleplanPatient}
                  className="p-button-rounded p-button-text py-2 w-max"
                  label={t('Teleplan')}
                  textAlign="center"
                  onClick={handleBillSameTeleplanPatient}
                />
                {!showNewTeleplanButtons && (
                  <>
                    <Button
                      id={elementIDs.billSameTeleplanPatientPOS}
                      className="p-button-rounded p-button-text py-2 w-max"
                      label={t('Picker')}
                      onClick={handleBillSameTeleplanPatientPOS}
                    />
                    <Button
                      id={elementIDs.billSameTeleplanPatient}
                      className="p-button-rounded p-button-text py-2 w-max"
                      label={t('Batch')}
                      onClick={handleBillSameTeleplanPatientGroup}
                    />
                  </>
                )}
              </>
            )}

            <Button
              id={elementIDs.billSamePrivatePatient}
              className="p-button-rounded p-button-text py-2 w-max"
              label={t('Private_invoice')}
              onClick={handleBillSamePrivatePatient}
            />

            {user.canBillWCB && (
              <Button
                id={elementIDs.billSameWSBCRPatient}
                className="p-button-rounded p-button-text py-2 w-max"
                label={t('WSBC_Report')}
                onClick={handleWSBCReportWithSamePatient}
              />
            )}

            {showEClaimButton && (
              <Button
                id={elementIDs.billSameEClaim}
                className="p-button-rounded p-button-text py-2 w-max"
                label={t('eClaims')}
                onClick={handleBillEClaimForSamePatient}
              />
            )}
          </div>
        </div>

        {/* BILL OTHER PATIENT */}
        <div className="buttonsSections card w-22rem">
          <div className="text-xl mb-3 font-semibold" style={{ textAlign: 'center', color: '#333', fontSize: '18px' }}>
            {t('Bill_other_patients')}
          </div>

          <div className="buttonsGap" style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
            {user.canBillMSP && (
              <>
                <Button
                  id={elementIDs.billOtherTeleplanPatient}
                  className="p-button-rounded p-button-text py-2 w-max"
                  label={t('Teleplan')}
                  onClick={handleBillOtherTeleplanPatient}
                />
                {!showNewTeleplanButtons && (
                  <>
                    <Button
                      id={elementIDs.billOtherTeleplanPatientPOS}
                      className="p-button-rounded p-button-text py-2 w-max"
                      label={t('Picker')}
                      onClick={handleBillOtherTeleplanPatientPOS}
                    />
                    <Button
                      id={elementIDs.billOtherTeleplanPatient}
                      className="p-button-rounded p-button-text py-2 w-max"
                      label={t('Batch')}
                      onClick={handleBillOtherTeleplanGoupPatient}
                    />
                  </>
                )}
                <Button
                  id={elementIDs.billOtherTeleplanFirstVisitPatient}
                  className="p-button-rounded p-button-text py-2 w-max"
                  label={t('First_visit')}
                  onClick={handleFirstVisit}
                />
              </>
            )}

            <Button
              id={elementIDs.billOtherPrivatePatient}
              className="p-button-rounded p-button-text py-2 w-max"
              label={t('Private_invoice')}
              onClick={handleBillOtherPrivatePatient}
            />

            {user.canBillWCB && (
              <Button
                id={elementIDs.billOtherWSBCRPatient}
                className="p-button-rounded p-button-text py-2 w-max"
                label={t('WSBC_Report')}
                onClick={handleWSBCReportWithOtherPatient}
              />
            )}
          </div>
        </div>

        {/* OPEN PROFILE */}
        <div className="buttonsSections card w-22rem">
          <div className="text-xl mb-3 font-semibold" style={{ textAlign: 'center', color: '#333', fontSize: '18px' }}>
            {t('Open_profile')}
          </div>

          <div className="buttonsGap" style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center', maxWidth: '100%' }}>
            <Button
              id={elementIDs.newPatient}
              className="p-button-rounded p-button-text py-2 w-max"
              style={{ display: 'flex' }}
              icon="pi pi-plus"
              label={t('New_Patient')}
              onClick={handleNewPatient}
            />

            {patientsList?.map((i, index) => (
              <>
                <Button
                  key={i.PatientGuid}
                  id={`${elementIDs.openPatientProfile}_${index}`}
                  className="p-button-rounded p-button-text py-2 w-max"
                  label={patientFullNameWithAge(i)}
                  style={{ whiteSpace: 'normal', wordBreak: 'break-word', maxWidth: '100%' }}
                  onClick={() => handleOpenPatientProfile(i.PatientGuid)}
                />
              </>
            ))}
          </div>
        </div>
      </div>

      <div className="flex w-full justify-content-center" style={{ paddingTop: '20px' }}>
        <Button
          id={elementIDs.goToPreviousPage}
          className="p-button-rounded p-button-text"
          label={t('Go_to_previous_page')}
          onClick={handleGoToPrevPage}
        />
      </div>
    </div>
  );
};

export default SuccessActionButtons;
