import { quantityEditor } from './quantityEditor';
import { practitionerEditor } from './practitionerEditor';
import { noteEditor } from './noteEditor';
import { discountEditor } from './discountEditor';
import { feeEditor } from './feeEditor';
import { salesTaxEditor } from './salesTaxEditor';
import { serviceEditor } from './serviceEditor';
import { addNewItemToArray } from '../../../../utils/addNewItemToArray';
import { t } from '../../../../../service/localization/i18n';
import * as bodyTemplate from './columnsBodyTemplate';

export const rowHeight = '2.5em';
export const noteRowHeight = '56px';

export const columns = ({
  records,
  setRecords,
  setIsReviewDirty,
  hideElems,
  isMobileOnly,
  onAddNote,
  setClaim,
  setDisplayConfirmationRP,
  isPractitionerIsEmpty,
  isMultiplePractitioners,
  mobileView
}) => {
  let commonItems = [
    {
      field: 'label',
      header: mobileView ? '' : `${t('Service_Product.1')}*`,
      editable: true,
      showInfoBtn: true,
      body: (params) => bodyTemplate.serviceBodyTemplate(params, setClaim, setDisplayConfirmationRP, mobileView, isMultiplePractitioners),
      editor: (params) => serviceEditor(params, mobileView)
    },

    {
      field: 'quantity',
      header: mobileView ? '' : `${t('Quantity.1').toUpperCase()}*`,
      editable: true,
      style: { width: mobileView ? '100%' : '9%' },
      body: (params) => bodyTemplate.genericBodyTemplate(params, 'quantity', t('Quantity.1').toUpperCase(), mobileView),
      editor: (params) => quantityEditor(params, records, setRecords, setIsReviewDirty, mobileView)
    },

    {
      field: 'amount',
      header: mobileView ? '' : `${t('Fee')}*`,
      editable: true,
      style: { width: mobileView ? '100%' : '9%' },
      body: (params) =>
        bodyTemplate.currencyBodyTemplate({
          id: params.id,
          value: params.amount,
          header: t('Fee'),
          mobileView
        }),
      editor: (params) => feeEditor(params, records, setRecords, setIsReviewDirty, mobileView)
    },

    {
      field: 'feeAmount',
      header: mobileView ? '' : t('Amount'),
      editable: false,
      style: { width: mobileView ? '100%' : '9%' },
      body: (params) =>
        bodyTemplate.currencyBodyTemplate({
          id: params.id,
          value: params.feeAmount,
          header: t('Amount'),
          mobileView
        })
    },

    {
      field: 'discount',
      header: mobileView ? '' : `${t('Discount.1')}*`,
      editable: true,
      style: { width: mobileView ? '100%' : '9%' },
      body: (params) => bodyTemplate.discountBodyTemplate(params, mobileView),
      editor: (params) => discountEditor(params, records, setRecords, setIsReviewDirty, mobileView)
    },

    {
      field: 'salesTax',
      header: mobileView ? '' : `${t('Sales_Tax')}*`,
      editable: true,
      style: { width: mobileView ? '100%' : '9%' },
      body: (params) => bodyTemplate.salesTaxBodyTemplate(params),
      editor: (params) => salesTaxEditor(params, records, setRecords, setIsReviewDirty, mobileView)
    },

    {
      field: 'total',
      header: mobileView ? '' : t('Total'),
      editable: false,
      style: { width: mobileView ? '100%' : '9%' },
      body: (params) =>
        bodyTemplate.currencyBodyTemplate({
          id: params.id,
          value: params.total,
          header: t('Total'),
          mobileView
        })
    }
  ];

  const practitioner = {
    field: 'doctorGuid',
    header: mobileView ? '' : `${t('Practitioner')}*`,
    editable: true,
    style: { width: '15%' },
    body: (params) => bodyTemplate.practitionerBodyTemplate(params, isPractitionerIsEmpty, setClaim, setDisplayConfirmationRP, mobileView),
    editor: (params) => practitionerEditor(params, records, setRecords, setIsReviewDirty, mobileView)
  };

  const note = {
    field: 'note',
    header: mobileView ? '' : `${t('Note')}`,
    editable: true,
    body: (params) => bodyTemplate.noteTemplate(params, mobileView),
    editor: (params) => noteEditor(params, records, setRecords, setIsReviewDirty, mobileView)
  };

  const actionButtons = {
    field: '',
    header: '',
    editable: false,
    style: { width: '50px' },
    hideActionBtn: true, // optional, only for mobile portrait mode
    body: (rowData) => bodyTemplate.actionButtonsTemplate(rowData, hideElems, isMobileOnly, onAddNote, setClaim, setDisplayConfirmationRP, records)
  };

  //add Note input
  if (mobileView) {
    commonItems = addNewItemToArray(commonItems, commonItems.length, note);
  }

  //add action buttons
  if (!mobileView) {
    commonItems = addNewItemToArray(commonItems, commonItems.length, actionButtons);
  }

  //add Practitioner input
  if (isMultiplePractitioners) {
    commonItems = addNewItemToArray(commonItems, 0, practitioner);
  }

  return commonItems;
};
