import { store } from '../../../../../store';
import { setShowMissingPatientDataDialog } from '../../../actions/claims.action.creators';

export const validatePatientProfile = (patient, replaceRoute = false, callback) => {
  if (!patient.PHN || patient.PHN === 'N/A' || !patient.AddressLine || !patient.City) {
    return store.dispatch(setShowMissingPatientDataDialog({ showDialog: true, replaceRoute }));
  }

  return callback();
};
