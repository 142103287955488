import React from 'react';
import { useHistory } from 'react-router-dom';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';

import { formatPHN } from '../../../utils/formatPHN';
import { formatDate } from '../../../utils/formatDate';
import { addNewOverlayItemsList } from '../../../claims/helpers/addNewOverlayItemsList';
import { addNewTeleplan } from '../../../claims/helpers/addNewInvoice';
import { elementIDs } from '../../../config/elementIDsConfig';
import { t } from '../../../../service/localization/i18n';

const DuplicatePHNDialog = ({ visible, onHide, onExit, duplicatedPatientsData, handleExistingProfile, addNewPatientToNewClaim = false }) => {
  const history = useHistory();

  return (
    <Dialog
      id={elementIDs.duplicatePHNDialog}
      header={t('Duplicate_records_alert')}
      visible={visible}
      breakpoints={{ '1630px': '50vw', '1366px': '70vw', '960px': '85vw', '768px': '95vw' }}
      style={{ width: '40vw' }}
      onHide={onHide}
    >
      <div className="flex flex-column align-items-center">
        <h6 className="mb-5">
          {String.format(
            t('A_profile_with_PHN_already_exists'),
            formatPHN(duplicatedPatientsData?.PHN, duplicatedPatientsData?.Province),
            `${duplicatedPatientsData?.FirstName} ${duplicatedPatientsData?.LastName}`,
            formatDate(duplicatedPatientsData?.BirthDay)
          )}
        </h6>

        <div className="flex flex-column md:flex-row w-full mt-2" style={{ columnGap: '10px', rowGap: '10px' }}>
          {!addNewPatientToNewClaim && (
            <SplitButton
              id={elementIDs.newClaimSplitButton}
              className="w-full"
              label={t('New_claim')}
              autoFocus
              model={addNewOverlayItemsList({
                history,
                patient_details: duplicatedPatientsData,
                replaceRoute: true,
                isSplitButton: true
              })}
              onClick={() => addNewTeleplan({ history, patient_details: duplicatedPatientsData, replaceRoute: true })}
            />
          )}

          <Button
            id={addNewPatientToNewClaim ? elementIDs.useExistingProfile : elementIDs.openExistingProfile}
            className="p-button-outlined w-full"
            label={addNewPatientToNewClaim ? t('Use_existing_profile') : t('Open_existing_profile')}
            onClick={() => handleExistingProfile(duplicatedPatientsData)}
          />

          <Button
            id={elementIDs.close}
            className="p-button-outlined w-full"
            label={t('Close')}
            tooltip={t('Close_this_message')}
            tooltipOptions={{ position: 'top' }}
            onClick={onHide}
          />

          <Button
            id={elementIDs.exit}
            className="p-button-outlined w-full"
            label={t('Exit')}
            tooltip={t('Exit_new_patient_form')}
            tooltipOptions={{ position: 'top' }}
            onClick={onExit}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DuplicatePHNDialog;
