import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';

import Success from '../Success/Success';
import WSBCReportPreviewContent from './WSBCReportPreviewContent';
import DeleteConfirmation from '../../../../../common/components/DeleteConfirmation';
import BatchRecordsWarnDialogForOnePatient from '../../../../../common/components/Dialogs/BatchRecordsWarnDialogForOnePatient';

import { openPatientProfile } from '../../../../helpers/rowActions';
import { formTypeDropdownOptions } from '../../helpers/dropdownOptions';
import { formatFormTypeValue } from '../../helpers/formTypeActions';
import { printTypes } from '../../../../../../components/Layouts/Print/helpers/printTypes';
import { closeToastMessage, onPrint, setToastMessage } from '../../../../../core/actions/core.action.creators';
import { addNewItemToArray } from '../../../../../utils/addNewItemToArray';
import { deleteClaim, getWSBCReport, saveWSBCReport } from '../../../../actions/claims.action.creators';
import { deleteClaimSuccess } from '../../../../helpers/toastMessages';
import { trimLeftZeros } from '../../../../../utils/trimLeftZeros';
import { inputs } from '../../helpers/inputs';
import { routes } from '../../../../../../routes/routes';
import { icons } from '../../../../../config/stylesConfig';
import { t } from '../../../../../../service/localization/i18n';
import moment from 'moment';

import './WSBCReportPreview.scss';
import { isRebillStatus } from '../../../../../utils/getStatus';

const WSBCReportPreview = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const menu = useRef(null);
  const { gettingWSBCReport, isSavingClaims } = useSelector((state) => state.claims);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showBatchRecordsWarn, setShowBatchRecordsWarn] = useState(false);
  const [data, setData] = useState({});
  const [claimData, setClaimData] = useState({});
  const [step, setStep] = useState(1);

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);

    // Get WSBC Report data
    return dispatch(getWSBCReport(params.id, (response) => setData(response)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Unmount component
  useEffect(() => {
    return () => {
      // Close toast message
      dispatch(closeToastMessage(true));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    const updatedData = { ...data, [inputs.sendNow.name]: true };

    dispatch(
      saveWSBCReport(updatedData, (response) => {
        if (response.error) {
          setShowBatchRecordsWarn(true);
          setClaimData(response);
          return;
        }

        setData(response);

        // Show success page
        setStep(2);
      })
    );
  };

  const handleEdit = () => {
    const invoiceGuid = data?.InvoiceGuid;
    const navTo = `${routes.wsbcReport.path}/${invoiceGuid}?replaceTo=${routes.wsbcReportPreview.path}/${invoiceGuid}`;
    history.replace(navTo);
  };

  const handleClose = () => {
    return history.goBack();
  };

  const handlePrint = () => {
    const type = formatFormTypeValue(data[inputs.formType.name], data[inputs.speciality.name]);
    const formType = formTypeDropdownOptions.find((i) => i.value === type)?.value;
    const serviceDate = moment(data[inputs.serviceDate.name]).format('YYYY-MM-DD');
    const patientName = `${data[inputs.patient.name]?.LastName} ${data[inputs.patient.name]?.FirstName}`;

    window.parent.document.title = `F${formType}-${serviceDate}-${patientName}`;
    dispatch(onPrint({ recordId: data[inputs.invoiceGuid.name], printType: printTypes().wsbcReport.type }));
  };

  const menuItems = () => {
    let commonItems = [
      {
        label: t('Patient.1'),
        icon: icons.patient,
        command: () => openPatientProfile(data[inputs.patientGuid.name], history, true)
      }
    ];

    const edit = {
      label: isRebillStatus(data?.Status) ? t('Rebill') : t('Edit'),
      icon: icons.edit,
      command: () => handleEdit()
    };

    const deleteReport = {
      label: t('Delete'),
      icon: icons.delete,
      command: () => setShowDeleteConfirm(true)
    };

    //show Edit button
    if (
      data[inputs.status.name] !== 1 && // 1 - In Process
      data[inputs.status.name] !== 201 && // 201 - In Process (Debit)
      data[inputs.status.name] !== 2 // 2 - Paid
    )
      commonItems = addNewItemToArray(commonItems, 0, edit);

    //show Delete button
    if (data[inputs.status.name] === 0 || data[inputs.status.name] === 4 || data[inputs.status.name] === 2) {
      commonItems = addNewItemToArray(commonItems, commonItems.length, deleteReport);
    }

    return commonItems;
  };

  const actionButtons = (
    <div className="flex gap-3">
      {data[inputs.status.name] === 0 && (
        <Button
          style={{ minWidth: '88px' }}
          className="p-button-outlined"
          type="submit"
          label={t('Submit')}
          disabled={gettingWSBCReport}
          loading={isSavingClaims}
          onClick={handleSubmit}
        />
      )}

      <Button
        className="p-button-text p-button-rounded"
        icon="pi pi-ellipsis-v"
        aria-controls="popup_menu"
        aria-haspopup
        tooltip={t('More')}
        tooltipOptions={{ position: 'top', showDelay: 500 }}
        onClick={(event) => menu.current.toggle(event)}
      />
      <Menu id="popup_menu" ref={menu} popup model={menuItems()} />

      <Button
        className="p-button-text p-button-rounded"
        icon={icons.print}
        tooltip={t('Print')}
        tooltipOptions={{ position: 'top', showDelay: 500 }}
        disabled={gettingWSBCReport}
        onClick={handlePrint}
      />

      <Button
        className="p-button-text p-button-rounded"
        tooltip={t('Close')}
        tooltipOptions={{ position: 'top', showDelay: 500 }}
        icon="pi pi-times"
        onClick={handleClose}
      />
    </div>
  );

  const deleteMessage = String.format(
    t('Are_you_sure_you_want_to_delete_invoice'),
    trimLeftZeros(data[inputs.recordNumber.name]),
    `${data[inputs.patient.name]?.LastName}, ${data[inputs.patient.name]?.FirstName}`
  );

  const onAcceptDeleteClaim = () => {
    dispatch(
      deleteClaim({
        invoiceGuid: data[inputs.invoiceGuid.name],
        patientGuid: data[inputs.patientGuid.name],
        callback: () => {
          history.goBack();
          dispatch(setToastMessage({ type: 'success', message: deleteClaimSuccess }));
        }
      })
    );
  };

  const onHideConfirmDialog = () => {
    setShowBatchRecordsWarn(false);
    setClaimData({});
  };

  return (
    <>
      {/* Content for displaying on screen */}
      {step === 1 && (
        <div className="flex justify-content-center preview_content_root no_printContent">
          <div className="flex flex-column justify-content-center align-items-center preview_root_container">
            <div className="flex justify-content-end align-items-center pb-3 pt-0 w-full actionButtonsWrap">{actionButtons}</div>

            <div className="card card_container">
              <WSBCReportPreviewContent data={data} loading={gettingWSBCReport} />
            </div>
          </div>
        </div>
      )}

      {step === 2 && <Success report={data} />}

      {/* Delete confirmation */}
      <DeleteConfirmation
        visible={showDeleteConfirm}
        header={t('Delete')}
        message={deleteMessage}
        accept={onAcceptDeleteClaim}
        reject={() => setShowDeleteConfirm(false)}
        onHide={() => setShowDeleteConfirm(false)}
      />

      <BatchRecordsWarnDialogForOnePatient
        data={claimData}
        visible={showBatchRecordsWarn}
        showOnInvoicesPage={true}
        showOnPatientDetailsScreen={false}
        onHide={() => onHideConfirmDialog()}
      />
    </>
  );
};

export default WSBCReportPreview;
