import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';

import Context from '../../../../Context';
import Success from './Success';
import PreviewPanel from './PreviewPanel';
import EditPanel from './EditPanel';
import CommonConfirmDialog from '../../../common/components/CommonConfirmDialog';
import CircularProgress from '../../../../components/Misc/Loader/CircularProgress/CircularProgress';

import { routes } from '../../../../routes/routes';
import { getItemFromArray, checkMandatoryFields } from '../../../../Helper';
import { requiredFields } from './claimDetailsHelpers/claimDetailsRequiredFields';
import { privateBlankRecord, updatePreferencesForPrivateRecord } from './claimDetailsHelpers/newClaimBlankRecord';
import { codesLocalStateInitValues } from '../../helpers/codesLocalStateInitValues';
import { setClean, setToastMessage } from '../../../core/actions/core.action.creators';
import { isEmpty, isEqual } from 'lodash';
import { prefsCodes } from '../../../config/prefsCodesConfig';
import { defaultGuid, multipleDoctorGuid, NA_DoctorGuid } from '../../../config/defaultValuesConfig';
import { getPreferences, setCurrentPractitionerPrefs } from '../../../preferences/actions/preferences.action.creators';
import {
  setPrivateRecord,
  setCreateClaimPanelCollapsed,
  getPrivateInvoicesCatalogCategories,
  getClaimInfo,
  updateClaimList
} from '../../actions/claims.action.creators';
import { getDefaultPrefs } from '../../helpers/getDefaultPrefs';
import { updatePractitionerValues } from './claimDetailsHelpers/updatePractitionerValues';
import { updateLocationValues } from './claimDetailsHelpers/updateLocationValues';
import { formatServices } from './claimDetailsHelpers/formatServices';
import { elementIDs } from '../../../config/elementIDsConfig';
import { invoicesCatalogsDefaultPageSize } from '../../../config/defaultValuesConfig';
import { t } from '../../../../service/localization/i18n';
import { initDoc } from './claimDetailsHelpers/initDoc';
import { inputs } from './claimDetailsHelpers/inputs';
import { practitionerType } from './claimDetailsHelpers/payorTypes';
import { addNewItemToArray } from '../../../utils/addNewItemToArray';

const PrivateDetails = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user) || {};
  const clinic = useSelector((state) => state.clinic);
  const { currentUserPrefs, savingPreferences, isGettingPrefs } = useSelector((state) => state.preferences);
  const { dirty } = useSelector((state) => state.core);
  const { isMobile, isMobileOnly } = useSelector((state) => state.core.window);
  const { showDataScroller } = useSelector((state) => state.core.showDataScroller);
  const {
    claim_list,
    complete_list,
    privateRecord,
    isSavingClaims,
    isFetchingClaims,
    shouldResetNewInvoiceForm,
    catalogMostRecentCodes,
    createClaimPanelCollapsed,
    isGettingPatientsMostReacentClaim
  } = useSelector((state) => state.claims);

  const itemsNumber = { serviceList: Array.from({ length: invoicesCatalogsDefaultPageSize }, () => 'item') };
  const [catalogsTableData, setCatalogsTableData] = useState(itemsNumber);
  const [step, setStep] = useState(0);
  const [records, setInitialRecords] = useState([]);
  const [menuModel, setMenuModel] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [editableDropdownErrors, setEditableDropdownErrors] = useState({});
  const [practitionerIsRequired, setPractitionerIsRequired] = useState(false);
  const [showSidebarWithCatalogs, setShowSidebarWithCatalogs] = useState(false);
  const [isMaxCodeEntries, setIsMaxCodeEntries] = useState({ isMaxEntries: false, maxEntries: 999 });
  const [codesLocalState, setCodesLocalState] = useState(codesLocalStateInitValues);
  const [showCatalogsSidebar, setShowCatalogsSidebar] = useState(false);
  const [isPractitionerIsEmpty, setIsPractitionerIsEmpty] = useState(false);
  const [isReviewDirty, setIsReviewDirty] = useState(false);
  const [claimIsDeleted, setClaimIsDeleted] = useState(false);
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);
  const [focusFieldParams, setFocusFieldParams] = useState({});
  const [catalogIndex, setCatalogIndex] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [mobileContent, setMobileContent] = useState('menu');
  const [categoryValue, setCategoryValue] = useState('');
  const [payToList, setPayToList] = useState([]);
  const [representative, setRepresentative] = useState(null);
  const [gettingRepresentative, setGettingRepresentative] = useState(null);

  const isDirty = dirty;
  const users = clinic.members;
  const isNew = props.match.url.indexOf('new') > -1;

  const currentUserDefaultPrefs = currentUserPrefs?.prefs?.find((i) => i.label === 'default');

  // Get catalog tabs
  useEffect(() => {
    if (privateRecord?.DoctorGuid && (privateRecord?.DoctorGuid !== NA_DoctorGuid || privateRecord?.DoctorGuid !== defaultGuid)) {
      dispatch(getPrivateInvoicesCatalogCategories(privateRecord?.DoctorGuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateRecord?.DoctorGuid]);

  // set initial record for new claim
  useEffect(() => {
    if (users?.length > 0) {
      if (!isNew) {
        // Set init list for PayTo component
        let defaultItems = [
          { ...initDoc(clinic.details), DoctorGuid: clinic.details?.DCNGuid, FullName: clinic.details?.ClinicName, Type: 'clinic' }
        ];
        const findCurrentDoc = users?.find((i) => i.DoctorGuid === privateRecord.DoctorGuid);
        const currentPractitoner = {
          ...initDoc(findCurrentDoc),
          DoctorGuid: findCurrentDoc?.DoctorGuid,
          FullName: findCurrentDoc?.FullName,
          Type: practitionerType
        };
        const payTo = privateRecord[inputs().payTo.name];

        if (
          privateRecord?.DoctorGuid === NA_DoctorGuid ||
          privateRecord?.DoctorGuid === multipleDoctorGuid ||
          privateRecord?.DoctorGuid?.toUpperCase() === clinic.details?.DCNGuid?.toUpperCase()
        ) {
          defaultItems = defaultItems?.filter((i) => i.DoctorGuid !== currentPractitoner?.DoctorGuid);
        } else {
          defaultItems = addNewItemToArray(defaultItems, 0, currentPractitoner);
        }

        if (payTo && payTo?.DoctorGuid && !defaultItems?.some((i) => i.DoctorGuid?.toUpperCase() === payTo.DoctorGuid?.toUpperCase())) {
          const payToPractitioner = {
            ...initDoc(payTo),
            DoctorGuid: payTo?.DoctorGuid,
            FullName: `${payTo?.LastName}, ${payTo?.FirstName}`,
            Type: practitionerType
          };
          defaultItems = addNewItemToArray(defaultItems, 0, payToPractitioner);
        }

        setPayToList(defaultItems);

        // Set init state
        dispatch(setPrivateRecord(privateRecord));
      }

      if (isNew) {
        if (isEmpty(privateRecord)) {
          setNewClaim();
        } else {
          if (!shouldResetNewInvoiceForm) {
            if (isEmpty(privateRecord?.CurrentUserPrefs)) {
              // add new invoice from patient profile
              setNewClaim(true);
            } else {
              // update invoice if user add new patient using "Add new patient" button
              dispatch(setPrivateRecord(privateRecord));
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNewClaim = (updateClaim = false) => {
    const getPractitionerId = currentUserDefaultPrefs?.content?.find((i) => i.label === prefsCodes.defaultPractitioner)?.value;
    const practitionerId =
      getPractitionerId === NA_DoctorGuid || getPractitionerId === multipleDoctorGuid ? user?.details?.UserGuid : getPractitionerId;

    dispatch(
      getPreferences(user.details.DCNGuid, practitionerId, (responceData) => {
        const defaultPrefs = responceData?.find((i) => i.label === 'default');
        newClaimRecord(updateClaim, practitionerId, defaultPrefs);
        dispatch(setCurrentPractitionerPrefs(defaultPrefs));
      })
    );
  };

  const newClaimRecord = (updateClaim, practitionerId, defaultPrefs) => {
    const payToInit =
      getDefaultPrefs(defaultPrefs?.content, prefsCodes.defaultPayTo) &&
      getDefaultPrefs(defaultPrefs?.content, prefsCodes.defaultPayTo)?.toUpperCase() === clinic?.details?.DCNGuid?.toUpperCase()
        ? clinic.details
        : getItemFromArray(users, practitionerId, 'DoctorGuid');
    const currentUser = getItemFromArray(users, practitionerId, 'DoctorGuid');
    const blankRecord = privateBlankRecord({
      user: currentUser,
      currentUserDefaultPrefs,
      currentPractitionerPrefs: defaultPrefs,
      payToInit,
      clinic
    });

    if (!updateClaim) dispatch(setPrivateRecord(blankRecord));

    if (updateClaim) {
      // if no last private invoice
      if (!privateRecord?.Patients?.LastPrivateGuid || privateRecord?.Patients?.LastPrivateGuid === defaultGuid) {
        const updatedClaim = {
          ...blankRecord,
          Patients: privateRecord?.Patients,
          BillTo: [privateRecord?.Patients]
        };

        dispatch(setPrivateRecord(updatePreferencesForPrivateRecord(updatedClaim, defaultPrefs)));
      }

      // if last private invoice is exist
      if (privateRecord?.Patients?.LastPrivateGuid !== defaultGuid) {
        dispatch(
          getClaimInfo(
            privateRecord?.Patients?.LastPrivateGuid,
            (responceData) => {
              const formattedResponceData = {
                ...responceData,
                FullName: `${responceData.DLastName}, ${responceData.DFirstName}`
              };
              const currentLocation =
                clinic.locations.find((i) => i.DCNGuid.toUpperCase() === formattedResponceData.DCNGuid.toUpperCase()) || clinic.locations[0];

              const updatedClaimWithLastInvoice = {
                ...blankRecord,
                ...updatePractitionerValues(formattedResponceData), // Update practitioner
                ...updateLocationValues(currentLocation), // Update location
                Patients: privateRecord?.Patients,
                BillTo: [responceData?.BillTo],
                PayTo: responceData?.PayTo,
                ServiceGuid: responceData?.ServicesList?.map((i) => i.value),
                ServicesList: formatServices(responceData)
              };

              dispatch(setPrivateRecord(updatePreferencesForPrivateRecord(updatedClaimWithLastInvoice, defaultPrefs)));
            },
            'P'
          )
        );
      }
    }
  };

  //component unmount
  useEffect(() => {
    return () => {
      //collapse HeaderPanel
      dispatch(setCreateClaimPanelCollapsed(true));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteInvoiceCallback = () => {
    // update claim list for ViewPanel
    const listOfClaims = isMobileOnly && showDataScroller ? complete_list : claim_list;
    const filteredListOfClaims = listOfClaims?.filter((i) => i.InvoiceGuid !== privateRecord?.InvoiceGuid);
    dispatch(updateClaimList(filteredListOfClaims));

    setClaimIsDeleted(true);
    dispatch(setClean());
    setStep(3);
  };

  const validateForm = (callback) => {
    const errors = Object.keys(editableDropdownErrors).some((i) => editableDropdownErrors[i] === true);
    const required = checkMandatoryFields('claim', privateRecord, requiredFields());

    //CMO-1320 - Create/Edit invoice - autoscroll to the mandatory field
    //Scroll to the invalid input
    const invalidInput = document.getElementsByClassName('p-invalid')[0]?.getElementsByTagName('input')[0];
    invalidInput?.focus();
    invalidInput?.scrollIntoView({ block: 'center', behavior: 'smooth' });

    // [KS]  Practitioner input validation
    if (privateRecord?.DoctorGuid === NA_DoctorGuid || privateRecord?.DoctorGuid === defaultGuid) {
      setPractitionerIsRequired(true);
      dispatch(setToastMessage({ type: 'warn', message: t('Please_complete_the_mandatory_fields'), lifeTime: 4000 }));
      return;
    }

    if (Object.keys(required).length !== 0) {
      setValidationErrors(required);
      dispatch(setToastMessage({ type: 'warn', message: t('Please_complete_the_mandatory_fields'), lifeTime: 4000 }));
      return;
    }

    if (Object.keys(required).length === 0 && errors) {
      dispatch(setToastMessage({ type: 'warn', message: t('Your_value_is_not_present_in_the_catalog') }));
      return;
    }

    if (!privateRecord?.ServicesList?.length) {
      dispatch(setToastMessage({ type: 'warn', message: t('Please_add_service_or_product_to_create_the_invoice') }));
      return;
    }

    // multiple practitioners
    if (
      (privateRecord?.DoctorGuid === multipleDoctorGuid || privateRecord?.DoctorGuid === user.details.DCNGuid) &&
      privateRecord?.ServicesList?.length
    ) {
      if (privateRecord?.ServicesList.some((i) => !i.doctorGuid || i.doctorGuid === NA_DoctorGuid)) {
        setIsPractitionerIsEmpty(true);
        dispatch(setToastMessage({ type: 'warn', message: t('Please_complete_the_mandatory_fields'), lifeTime: 4000 }));
        return;
      }
    }

    if (Object.keys(required).length === 0 && !errors) {
      // dispatch(updatePrivateRecord({
      //   ...privateRecord,
      //   [inputs().privateService.descriptionName]: formatServices(privateRecord)
      // }));
      // setStep(1);

      callback();
    }
  };

  const promptMessage = (location) => {
    //Will be called with the next location and action the user is attempting to navigate to. Return a string to show a prompt to the user or true to allow the transition.
    return location.pathname.startsWith(`${routes.createPatient.path}/new`)
      ? true
      : JSON.stringify({
          isDirty,
          name: 'privateClaim' //add 'name' to check which form should be nulled (see customPrompt for nulling)
        });
  };

  const contextValue = {
    representative,
    setRepresentative,
    gettingRepresentative,
    setGettingRepresentative,
    payToList,
    menuModel,
    setMenuModel,
    deleteInvoiceCallback,
    categoryValue,
    setCategoryValue,
    mobileContent,
    setMobileContent,
    searchValue,
    setSearchValue,
    isLoadingTableData,
    setIsLoadingTableData,
    itemsNumber,
    catalogsTableData,
    setCatalogsTableData,
    claimIsDeleted,
    setClaimIsDeleted,
    isPractitionerIsEmpty,
    setIsPractitionerIsEmpty,
    isReviewDirty,
    setIsReviewDirty,
    records,
    setInitialRecords,
    setIsMaxCodeEntries,
    setNewClaim,
    setStep,
    showCatalogsSidebar,
    setShowCatalogsSidebar,
    focusFieldParams,
    setFocusFieldParams,
    catalogIndex,
    setCatalogIndex,
    setShowSidebarWithCatalogs,
    showSidebarWithCatalogs,
    privateRecord,
    dropdownErrors: editableDropdownErrors,
    setDropdownErrors: setEditableDropdownErrors,
    codesLocalState,
    setCodesLocalState,
    validateForm,
    isMobile,
    isDirty,
    isNew,
    user,
    users: clinic.members,
    clinic: clinic.details,
    locations: clinic.locations,
    shouldResetNewInvoiceForm,
    errors: validationErrors,
    setValidationErrors,
    currentUserDefaultPrefs,
    savingPreferences,
    catalogMostRecentCodes,
    createClaimPanelCollapsed,
    practitionerIsRequired,
    setPractitionerIsRequired
  };

  const showEditPanel = step === 0;
  const showPreviewPanel = step === 2;
  const showSuccessPage = step === 3;
  const showSpinner = savingPreferences || isGettingPrefs || isSavingClaims || isGettingPatientsMostReacentClaim;

  return (
    <Context.Provider value={contextValue}>
      <div id={elementIDs.privateInvoiceFormContainer} className="px-0 md:px-3">
        <Prompt when={isDirty} message={promptMessage} />

        <CommonConfirmDialog
          header={t('Warning')}
          visible={isMaxCodeEntries.isMaxEntries}
          multipleButtons={false}
          bodyText={`${t('Maximum_number_of_entries_is_X')} ${isMaxCodeEntries.maxEntries}`}
          accept={() => setIsMaxCodeEntries({ isMaxEntries: false, maxEntries: 999 })}
          reject={() => setIsMaxCodeEntries({ isMaxEntries: false, maxEntries: 999 })}
        />

        {showSpinner && <CircularProgress />}

        {showEditPanel && <EditPanel />}

        {showPreviewPanel && <PreviewPanel />}

        {showSuccessPage && <Success />}
      </div>
    </Context.Provider>
  );
};

export default memo(PrivateDetails, isEqual);
