import React from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { currencyFormat } from '../../../../utils/currencyFormat';
import { salesTaxList } from './salesTaxList';
import { rowHeight } from './columns';
import { t } from '../../../../../service/localization/i18n';
import { elementIDs } from '../../../../config/elementIDsConfig';
import cx from 'classnames';
import { inputs } from '../claimDetailsHelpers/inputs';
import { store } from '../../../../../store';
import { updatePrivateRecord } from '../../../actions/claims.action.creators';

export const actionButtonsTemplate = (rowData, hideElems, isMobileOnly, onAddNote, setClaim, setDisplayConfirmationRP, records) => {
  const menu = React.createRef();
  return (
    <>
      {rowData.id === '-1' && (
        <Button
          icon="pi pi-trash"
          className="p-button-text p-button-rounded"
          tooltip={t('Delete_claim')}
          tooltipOptions={{ position: 'top' }}
          onClick={() => {
            const updatedServicesCodes = records.filter((i) => i.value);
            const privateServiceCodes = updatedServicesCodes?.map((x) => x.value);

            store.dispatch(
              updatePrivateRecord({
                [inputs().privateService.name]: privateServiceCodes,
                [inputs().privateService.descriptionName]: updatedServicesCodes
              })
            );
          }}
        />
      )}

      {rowData.id !== '-1' && (
        <>
          {hideElems ? (
            <div className="flex justify-content-around">
              <Button
                icon="pi pi-comment"
                className="p-button-text p-button-rounded"
                tooltip="Add note"
                tooltipOptions={{ position: 'top' }}
                onClick={() => onAddNote(rowData, true)}
              />
              <Button
                icon="pi pi-trash"
                className="p-button-text p-button-rounded"
                tooltip={t('Delete_claim')}
                tooltipOptions={{ position: 'top' }}
                onClick={() => confirmDeleteProduct(rowData, setClaim, setDisplayConfirmationRP)}
              />
            </div>
          ) : (
            <div className={cx('flex justify-content-end', isMobileOnly ? 'p-mt--1' : '')}>
              <Menu id={`popup_menu_${rowData.id}`} ref={menu} popup model={items(rowData, onAddNote, setClaim, setDisplayConfirmationRP)} />
              <Button
                id={rowData.id.replaceAll('-', '')}
                className="p-button-text p-button-rounded mobileRoundedButtonSize"
                icon="pi pi-ellipsis-v"
                aria-controls={`popup_menu_${rowData.id}`}
                aria-haspopup
                onClick={(event) => menu.current.toggle(event)}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export const serviceBodyTemplate = (params, setClaim, setDisplayConfirmationRP, mobileView = false, isMultiplePractitioners) => {
  const duration = params.duration ? params.duration : '';
  const serviceText = params.unit ? `${params.label}, ${duration} ${params.unit}` : `${params.label}`;

  return (
    <div
      className={cx('flex', mobileView ? 'align-items-start pt-3' : 'align-items-center', isMultiplePractitioners || !mobileView ? '' : '')}
      style={{ minHeight: rowHeight }}
    >
      <span className="p-column-title">{t('Service_Product.1')}</span>
      <div className={cx('flex justify-content-between w-full', mobileView ? 'align-items-start' : 'align-items-center')}>
        <div id={`${elementIDs.serviceBodyTemplate}_${params.id?.replaceAll('-', '').toUpperCase()}`} className="pr-2">
          {params.label ? serviceText : t('Select_service')}
        </div>
        {mobileView && params?.id !== '-1' && !isMultiplePractitioners && (
          <Button
            id={elementIDs.delete}
            className="p-button-text p-button-rounded p-mt--1"
            icon="pi pi-trash"
            style={{ width: '2rem', height: '2rem' }}
            onClick={() => confirmDeleteProduct(params, setClaim, setDisplayConfirmationRP)}
          />
        )}
      </div>
    </div>
  );
};

export const salesTaxBodyTemplate = (params) => {
  const currentTax = salesTaxList?.find((i) => i.name === params.salesTax)?.text;
  return (
    <div className="flex align-items-center" style={{ height: rowHeight, color: params.id === '-1' ? `rgba(0, 0, 0, 0.87)` : '' }}>
      <span className="p-column-title">{t('Tax').toUpperCase()}</span>
      <span id={`${elementIDs.salesTaxBodyTemplate}_${params.id?.replaceAll('-', '').toUpperCase()}`}>{currentTax}</span>
    </div>
  );
};

export const genericBodyTemplate = (params, field, header, mobileView) => {
  const genericValue = params[field] === 0 || params[field] === '' ? '―' : params[field];

  return (
    <div
      className={cx('flex', mobileView ? 'align-items-start' : 'align-items-center')}
      style={{ height: rowHeight, color: params?.id === '-1' ? `rgba(0, 0, 0, 0.87)` : '' }}
    >
      <span className="p-column-title">{header}</span>
      {genericValue}
    </div>
  );
};

export const noteTemplate = (params, mobileView) => {
  const value = params?.note?.length ? params?.note : '―';
  return (
    <div
      className={cx('flex', mobileView ? 'align-items-start' : 'align-items-center mb-3')}
      style={{ height: rowHeight, color: params.id === '-1' ? `rgba(0, 0, 0, 0.87)` : '' }}
    >
      <span className="p-column-title">{t('Note')}</span>
      <div>{value}</div>
    </div>
  );
};

export const practitionerBodyTemplate = (params, isPractitionerIsEmpty, setClaim, setDisplayConfirmationRP, mobileView) => {
  const title = t('Practitioner');
  const color = () => {
    if (!params.doctorGuid && isPractitionerIsEmpty) return '';
    return params.id === '-1' ? `rgba(0, 0, 0, 0.87)` : '';
  };
  const body = () => {
    if (!params.doctorGuid) {
      if (isPractitionerIsEmpty) return t('Mandatory_field.1');
      if (!isPractitionerIsEmpty) return t('N_A');
    }

    return params.treatedBy;
  };

  return (
    <div className={cx('flex', mobileView ? 'align-items-start mt-3' : 'align-items-center')} style={{ height: rowHeight, color: color() }}>
      <div className={cx('flex justify-content-between w-full', mobileView ? 'align-items-start' : 'align-items-center')}>
        <div className={cx('pr-2', body() === t('Mandatory_field.1') ? 'p-error' : '')}>
          <span className="p-column-title">{title}</span>
          <span>{body()}</span>
        </div>

        {mobileView && params.id !== '-1' && (
          <Button
            className="p-button-text p-button-rounded p-mt--1"
            icon="pi pi-trash"
            style={{ width: '2rem', height: '2rem' }}
            onClick={() => confirmDeleteProduct(params, setClaim, setDisplayConfirmationRP)}
          />
        )}
      </div>
    </div>
  );
};

export const currencyBodyTemplate = ({ value, header, id, idText = '', mobileView }) => {
  return (
    <div
      className={cx('flex', mobileView ? 'align-items-start' : 'align-items-center')}
      style={{ height: rowHeight, color: id === '-1' ? `rgba(0, 0, 0, 0.87)` : '' }}
    >
      <span className="p-column-title">{header}</span>
      <span id={`${idText}_${id?.replaceAll('-', '').toUpperCase()}`}>{currencyFormat(value)}</span>
    </div>
  );
};

export const discountBodyTemplate = (params, mobileView) => {
  const _unit = params.discountUnits === 'percent' ? `${params.discount}%` : `$${params.discount}`;
  const discountValue = params.discount === 0 || params.discount === '' ? '―' : _unit;

  return (
    <div
      className={cx('flex', mobileView ? 'align-items-start' : 'align-items-center')}
      style={{ height: rowHeight, color: params.id === '-1' ? `rgba(0, 0, 0, 0.87)` : '' }}
    >
      <span className="p-column-title">{`${t('Discount.1')}`}</span>
      {discountValue}
    </div>
  );
};

export const percentsBodyTemplate = (params, field) => {
  if (params[field] === 0 || params[field] === '') {
    return '―';
  } else {
    return `${params[field]}%`;
  }
};

const confirmDeleteProduct = (item, setClaim, setDisplayConfirmationRP) => {
  setClaim(item);
  setDisplayConfirmationRP(2);
};

const items = (rowData, onAddNote, setClaim, setDisplayConfirmationRP) => {
  return [
    {
      label: t('Add_note'),
      icon: 'pi pi-comment',
      command: () => onAddNote(rowData, true)
    },
    {
      label: t('Delete'),
      icon: 'pi pi-trash',
      command: () => confirmDeleteProduct(rowData, setClaim, setDisplayConfirmationRP)
    }
  ];
};
