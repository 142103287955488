import PrivateService from '../PrivateService';
import { t } from '../../../../../service/localization/i18n';
import cx from 'classnames';

export const serviceEditor = (params, mobileView) => {
  return (
    <div
      className={cx('flex', mobileView ? 'align-items-start pt-3' : 'align-items-center')}
      style={mobileView ? {} : { position: 'absolute', top: '10px', zIndex: '1' }}
    >
      <span className="p-column-title">{t('Service_Product.1')}</span>
      <PrivateService rowData={params?.rowData} />
    </div>
  );
};
