import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import MenuLayout from './MenuLayout';
import MenuItemRender from './MenuItemRender';
import GlobalSearch from '../GlobalSearch';

import { elementIDs } from '../../../config/elementIDsConfig';
import { routes } from '../../../../routes/routes';
import { t } from '../../../../service/localization/i18n';

const CreatePatient = () => {
  const history = useHistory();

  const items = useMemo(() => {
    const items = [
      {
        label: <div className="font-bold">{`Create patient`}</div>,
        items: [
          {
            id: elementIDs.addNewPatient,
            label: t('New'),
            icon: 'pi pi-plus',
            command: () => history.push(`${routes.createPatient.path}/new`),
            template: (item) => <MenuItemRender item={item} />
          },
          {
            id: elementIDs.addNewborn,
            label: 'Newborn',
            icon: 'pi pi-plus',
            command: () => history.push(`${routes.createNewborn.path}/new`),
            template: (item) => <MenuItemRender item={item} />
          }
        ]
      }
    ];

    items.push({
      template: () => (
        <div className="pt-3 pb-2 px-2">
          <GlobalSearch name="patient" placeholder="Search patient" />
        </div>
      )
    });

    return items;
  }, [history]);

  return <MenuLayout model={items} />;
};

export default CreatePatient;
